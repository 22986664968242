import React, { Fragment, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {
  GridSelect,
  GridInput,
  GridDatePicker,
  InputText,
} from "../components/InputComponents";
import { prefixs, prefixs_alt } from "../const";
import Conf from "../Conf";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});
export default function FormPersonal(props) {
  const filePackageSize = 300000;
  const classes = useStyles();
  const navigate = useNavigate();
  const options = props.options;
  const { id } = useParams();
  const [form, setForm] = useState();
  useEffect(() => {
    setForm(props.employee);
  }, [props]);

  async function uploadFile() {
    return new Promise(async (resolve) => {
      let len = form.new_photo.length;
      let file_id = null;
      for (let i = 0; i < len; i += filePackageSize) {
        let file_stream = form.new_photo.substring(i, i + filePackageSize);
        let send = {
          file_id,
          file_stream,
        };
        let result = await axios.post(Conf.endpoint.upload, send);
        if (result.data.file_id) file_id = result.data.file_id;
      }
      resolve(file_id);
    });
  }

  async function save() {
    let send = { ...form };
    if (form.new_photo) {
      send.photo_url = await uploadFile();
      send.photo_url = Conf.endpoint.file + "?id=" + send.photo_url;
      delete send.new_photo;
    }
    let result = await axios.put(Conf.endpoint.app + "/employee", send, {
      headers: { Authorization: cookie.access_token },
    });
    if (result.data.error) alert(result.data.error.message);
    else {
      alert("Save completed");
      if (result.data._id !== id) {
        navigate(process.env.PUBLIC_URL + "/person/" + result.data._id);
      }
    }
  }
  return (
    <div>
      {form && (
        <>
          <div className={classes.head1}>ข้อมูลส่วนตัวบุคลากร</div>
          <Grid container spacing={2}>
            <>
              <GridSelect
                grid={[12, 6, 3, 3]}
                label="Prefix"
                options={prefixs || []}
                onChange={(val) => setForm({ ...form, prefix: val })}
                value={form.prefix || "-"}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="First name"
                placeholder="ชื่อ"
                onChange={(val) => setForm({ ...form, first_name: val })}
                value={form.first_name}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Middle name"
                placeholder="ชื่อกลาง"
                onChange={(val) => setForm({ ...form, middle_name: val })}
                value={form.middle_name}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Last name"
                placeholder="นามสกุล"
                onChange={(val) => setForm({ ...form, last_name: val })}
                value={form.last_name}
              />
              <GridSelect
                grid={[12, 6, 3, 3]}
                label="Prefix (EN)"
                options={prefixs_alt || []}
                onChange={(val) => setForm({ ...form, prefix_alt: val })}
                value={form.prefix_alt || "-"}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="First name (EN)"
                placeholder="ชื่อ (EN)"
                onChange={(val) => setForm({ ...form, first_name_alt: val })}
                value={form.first_name_alt}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Middle name (EN)"
                placeholder="ชื่อกลาง (EN)"
                onChange={(val) => setForm({ ...form, middle_name_alt: val })}
                value={form.middle_name_alt}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Last name (EN)"
                placeholder="นามสกุล (EN)"
                onChange={(val) => setForm({ ...form, last_name_alt: val })}
                value={form.last_name_alt}
              />
            </>
            <>
              <GridInput
                grid={[12, 6, 4, 4]}
                label="Employee ID"
                placeholder="หมายเลขพนักงาน"
                onChange={(val) => setForm({ ...form, employee_id: val })}
                value={form.employee_id}
              />
              <GridSelect
                grid={[12, 6, 4, 4]}
                label="Gender"
                options={[{ label: "ชาย" }, { label: "หญิง" }]}
                onChange={(val) => setForm({ ...form, gender: val })}
                value={form.gender || "-"}
              />
              <GridInput
                grid={[12, 6, 4, 4]}
                label="Nationality"
                placeholder="สัญชาติ"
                onChange={(val) => setForm({ ...form, nationality: val })}
                value={form.nationality}
              />
              <GridInput
                grid={[12, 6, 4, 4]}
                label="Domicile"
                placeholder="ภูมิลำเนา"
                onChange={(val) => setForm({ ...form, domicile: val })}
                value={form.domicile}
              />

              <GridSelect
                grid={[12, 6, 4, 4]}
                label="License type"
                options={options.hr_license_type}
                onChange={(val) => setForm({ ...form, license_type: val })}
                value={form.license_type || "-"}
              />
              <GridInput
                grid={[12, 6, 4, 4]}
                label="ID card"
                placeholder="บัตรประชาชน"
                onChange={(val) => setForm({ ...form, idx: val })}
                value={form.idx}
              />

              <GridDatePicker
                grid={[12, 6, 3, 3]}
                label="VISA Expore date"
                placeholder="วันหมดอายุวีซ่า"
                onChange={(val) => setForm({ ...form, visa_expire_date: val })}
                value={form.visa_expire_date || null}
              />

              <GridInput
                grid={[12, 6, 3, 3]}
                label="Nickname"
                placeholder="ชื่อเล่น"
                onChange={(val) => setForm({ ...form, nickname: val })}
                value={form.nickname}
              />
              <GridDatePicker
                grid={[12, 6, 3, 3]}
                label="Birth date"
                placeholder="วันเกิด"
                onChange={(val) => setForm({ ...form, birth_date: val })}
                value={form.birth_date || null}
              />
            </>
            <>
              <GridSelect
                grid={[12, 6, 3, 3]}
                label="Blood group"
                options={[
                  { label: "A" },
                  { label: "B" },
                  { label: "AB" },
                  { label: "O" },
                ]}
                onChange={(val) => setForm({ ...form, blood_group: val })}
                value={form.blood_group || "-"}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Phone Number"
                placeholder="โทรศัพท์"
                onChange={(val) => setForm({ ...form, phone_no: val })}
                value={form.phone_no}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Line ID"
                placeholder="ไลน์ ไอดี"
                onChange={(val) => setForm({ ...form, line_id: val })}
                value={form.line_id}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="Facebook"
                placeholder="เฟซบุ๊ก"
                onChange={(val) => setForm({ ...form, facebook: val })}
                value={form.facebook}
              />
              <GridInput
                grid={[12, 6, 3, 3]}
                label="e-Mail"
                placeholder="อีเมล"
                onChange={(val) => setForm({ ...form, email: val })}
                value={form.email}
              />
            </>
          </Grid>
          <UploadPhoto
            photo={{
              photo_url: form.photo_url,
              new_photo: form.new_photo,
            }}
            onChange={(field, value) => setForm({ ...form, [field]: value })}
          />
          <div className={classes.head1}>โรคประจำตัว</div>
          <AllergyTable
            data={form.congenital_disease || []}
            updateTable={(val) => setForm({ ...form, congenital_disease: val })}
          />
          <div className={classes.head1}>การแพ้ยา</div>
          <AllergyTable
            data={form.drug_allergy || []}
            updateTable={(val) => setForm({ ...form, drug_allergy: val })}
          />
          <div className={classes.head1}>
            บุคคลที่สามารถติดต่อได้กรณีฉุกเฉิน
          </div>
          <Grid container spacing={2}>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Prefix"
              options={prefixs || []}
              onChange={(val) => setForm({ ...form, emergency_prefix: val })}
              value={form.emergency_prefix || "-"}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="First name"
              placeholder="ชื่อ"
              onChange={(val) =>
                setForm({ ...form, emergency_first_name: val })
              }
              value={form.emergency_first_name}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Middle name"
              placeholder="ชื่อกลาง"
              onChange={(val) =>
                setForm({ ...form, emergency_middle_name: val })
              }
              value={form.emergency_middle_name}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Last name"
              placeholder="นามสกุล"
              onChange={(val) => setForm({ ...form, emergency_last_name: val })}
              value={form.emergency_last_name}
            />
            <GridInput
              grid={[12, 12, 12, 12]}
              label="Address"
              placeholder="ที่อยู่"
              onChange={(val) => setForm({ ...form, emergency_address: val })}
              value={form.emergency_address}
            />
            <GridInput
              grid={[12, 6, 6, 6]}
              label="Relation"
              placeholder="ความสัมพันธ์"
              onChange={(val) => setForm({ ...form, emergency_relation: val })}
              value={form.emergency_relation}
            />
            <GridInput
              grid={[12, 6, 6, 6]}
              label="Phone number"
              placeholder="เบอร์โทรศัพท์"
              onChange={(val) => setForm({ ...form, emergency_phone: val })}
              value={form.emergency_phone}
            />
          </Grid>
          <div style={{ paddingTop: "20px", textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={save}
              style={{ width: "150px" }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function UploadPhoto({ photo, onChange }) {
  const classes = useStyles();
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      let { new_photo, photo_url } = photo;
      if (new_photo) {
        setImg(new_photo);
      } else if (photo_url) {
        let img = await axios.get(photo_url);
        if (img.data) setImg(img.data);
      }
    })();
  }, [photo]);

  function changeImage(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        onChange("new_photo", reader.result);
      };
    }
  }

  return (
    <div>
      <div className={classes.head1}>รูปถ่าย</div>
      <div style={{ textAlign: "center" }}>
        {img ? (
          <>
            <img
              src={img}
              alt=""
              style={{ maxHeight: "200px", border: "2px solid #ddd" }}
            />
          </>
        ) : (
          <Icon style={{ fontSize: "10rem", color: "#999" }}>person</Icon>
        )}

        <div>
          <label htmlFor={"student-img"}>
            <input
              accept="image/png,image/jpeg,image/PNG,image/JPEG"
              id="student-img"
              type="file"
              onChange={changeImage}
              style={{ display: "none" }}
            />
            <Button
              aria-label="upload picture"
              component="span"
              startIcon={<Icon>photo_camera</Icon>}
            >
              Upload Photo
            </Button>

            {/* <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Icon>photo_camera</Icon>
            </IconButton> */}
          </label>
        </div>
      </div>
    </div>
  );
}

function AllergyTable({ data, updateTable }) {
  const [form, setForm] = useState({});
  function addTable() {
    if (form.description && form.description !== "") {
      let d = JSON.parse(JSON.stringify(data));
      d.push(form);
      updateTable(d);
      setForm({});
    }
  }
  function removeTable(item) {
    let d = JSON.parse(JSON.stringify(data));
    d = d.filter((j) => j.description !== item.description);
    updateTable(d);
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{ width: "80px" }}
              >
                No.
              </TableCell>
              <TableCell className="th" align="center">
                Description
              </TableCell>
              <TableCell style={{ width: "80px" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="inputTd" align="center">
                <Icon>add</Icon>
              </TableCell>
              <TableCell className="inputTd" align="center">
                <InputText
                  placeholder="รายละเอียด"
                  onChange={(val) => setForm({ ...form, description: val })}
                  value={form.description}
                />
              </TableCell>
              <TableCell className="controlTd" align="center">
                <IconButton color="primary" onClick={addTable}>
                  <Icon style={{ fontSize: "1.8rem" }}>add_circle</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
            {data &&
              data.map((item, key) => (
                <TableRow key={key}>
                  <TableCell align="center" className="td">
                    {key + 1}
                  </TableCell>
                  <TableCell className="td">{item.description}</TableCell>
                  <TableCell className="controlTd" align="center">
                    <IconButton color="error" onClick={() => removeTable(item)}>
                      <Icon style={{ fontSize: "1.8rem" }}>remove_circle</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
