import React, { Fragment, useEffect, useState } from "react";

import { InputText } from "./InputComponents";
import Grid from "@mui/material/Grid";

import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});

export default function FormGeneral(props) {
  const classes = useStyles();
  const [employee, setEmployee] = useState();
  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee]);

  function saveCar(car, callback, key) {
    let emp = { ...employee };
    if (key > -1) {
      emp.cars[key] = car;
      emp.cars[key].edit = false;
    } else {
      if (!emp.cars) emp.cars = [];
      emp.cars.push(car);
    }
    setEmployee(emp);
    callback(true);
  }
  function deleteCar(key) {
    if (window.confirm("Confirm to delete")) {
      let emp = { ...employee };
      emp.cars.splice(key, 1);
      setEmployee(emp);
    }
  }

  async function save() {
    let send = { ...employee };
    props.onSave(send);
  }
  return (
    <div>
      {employee && (
        <>
          <div className={classes.head1}>ข้อมูลบัญชีธนาคาร</div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputText
                placeholder="เลขที่บัญชีธนาคาร"
                label="Account No."
                onChange={(val) =>
                  setEmployee({ ...employee, bank_account_no: val })
                }
                value={employee.bank_account_no}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputText
                placeholder="สาขาธนาคาร"
                label="Bank branch"
                onChange={(val) =>
                  setEmployee({ ...employee, bank_branch: val })
                }
                value={employee.bank_branch}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputText
                placeholder="ประเภทบัญชี"
                label="Account type"
                onChange={(val) =>
                  setEmployee({ ...employee, bank_account_type: val })
                }
                value={employee.bank_account_type}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.head1}>ข้อมูลรถยนต์</div>
              <CarTable
                cars={employee.cars || []}
                onSave={saveCar}
                onDelete={deleteCar}
                onSelect={(key) => {
                  let Emp = { ...employee };
                  Emp.cars[key].edit = !Emp.cars[key].edit;
                  setEmployee(Emp);
                }}
              />
            </Grid>
          </Grid>
          <div style={{ paddingTop: "20px", textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={save}
              style={{ width: "150px" }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function CarTable({ cars, onSave, onSelect, onDelete }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="th">ลำดับ</TableCell>
              <TableCell className="th" style={{ width: "20%" }}>
                ประเภทรถยนต์
              </TableCell>
              <TableCell className="th" style={{ width: "20%" }}>
                เลขทะเบียน
              </TableCell>
              <TableCell className="th" style={{ width: "20%" }}>
                ยี่ห้อ
              </TableCell>
              <TableCell className="th" style={{ width: "20%" }}>
                สี
              </TableCell>
              <TableCell className="th" style={{ width: "10%" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <InputRow onSave={onSave} />
            {cars.map((car, key) => (
              <Fragment key={key}>
                {car.edit === true ? (
                  <InputRow
                    onSave={(car, callback) => onSave(car, callback, key)}
                    onUndo={() => onSelect(key)}
                    onDelete={() => onDelete(key)}
                    car={car}
                  />
                ) : (
                  <ShowRow
                    car={car}
                    no={key + 1}
                    onSelect={() => onSelect(key)}
                  />
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function ShowRow({ car, no, onSelect }) {
  return (
    <TableRow>
      <TableCell align="center">{no}</TableCell>
      <TableCell align="center">{car.type}</TableCell>
      <TableCell align="center">{car.license_no}</TableCell>
      <TableCell align="center">{car.brand}</TableCell>
      <TableCell align="center">{car.color}</TableCell>
      <TableCell align="center" className="controlTd">
        <IconButton color="primary" component="span" onClick={() => onSelect()}>
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function InputRow({ car, onSave, onUndo, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    setForm(car || {});
  }, [car]);

  return (
    <>
      {form && (
        <TableRow>
          <TableCell align="center" className="controlTd">
            {!car ? (
              <>
                <Icon style={{ color: "#777" }}>add</Icon>
              </>
            ) : (
              <IconButton color="error" component="span" onClick={onDelete}>
                <Icon>clear</Icon>
              </IconButton>
            )}
          </TableCell>

          <TableCell className="inputTd">
            <Select
              native
              value={form.type || "-"}
              onChange={(e) => setForm({ ...form, type: e.target.value })}
              fullWidth
              size="small"
            >
              <option value="-"></option>
              <option value="รถยนต์">รถยนต์</option>
              <option value="รถมอเตอร์ไซค์">รถมอเตอร์ไซค์</option>
            </Select>
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, license_no: val })}
              value={form.license_no}
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, brand: val })}
              value={form.brand}
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, color: val })}
              value={form.color}
            />
          </TableCell>
          <TableCell className="controlTd" align="center">
            {car && (
              <IconButton color="success" component="span" onClick={onUndo}>
                <Icon>undo</Icon>
              </IconButton>
            )}
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                onSave(form, (success) => {
                  if (success === true) setForm({});
                });
              }}
            >
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
