import React from "react";
import Icon from "@mui/material/Icon";

export default function ProfileBox(props) {
  return (
    <div style={{ color: "#666", fontSize: ".8rem" }}>
      <div style={{ float: "right" }}>
        {props.profile && props.profile.display_name}
      </div>
      <div style={{ float: "right", marginRight: "5px" }}>
        <Icon style={{ fontSize: "1.1rem" }}>account_circle</Icon>
      </div>
    </div>
  );
}
