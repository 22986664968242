import React, { Fragment, useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import uuid from "react-uuid";

export default function ExtraIncomeTable({ contract, onSave, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (contract) {
      if (!contract.extra_income) contract.extra_income = [];
      for (let extra of contract.extra_income) {
        extra.uuid = uuid();
        extra.edit = false;
      }
      setForm(contract);
    }
  }, [contract]);
  function saveExtra(extra) {
    let newExtra = JSON.parse(JSON.stringify(form.extra_income || []));
    if (extra.uuid) {
      let i = newExtra.findIndex((j) => j.uuid === extra.uuid);
      extra.edit = false;
      newExtra[i] = extra;
    } else {
      extra.uuid = uuid();
      newExtra.push(extra);
    }
    onSave(newExtra);
  }
  function deleteExtra(id) {
    onDelete(id);
    /*
      let Form = JSON.parse(JSON.stringify(form));
      Form.extra_income = Form.extra_income.filter((j) => j.uuid !== uuid);
      setForm(Form);
      */
  }
  function setEdit(key) {
    let Form = JSON.parse(JSON.stringify(form));
    Form.extra_income[key].edit = true;
    setForm(Form);
  }
  return (
    <>
      {form && (
        <>
          <div>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "60px" }} align="center">
                      No
                    </TableCell>
                    <TableCell align="center">Income Name</TableCell>
                    <TableCell style={{ width: "200px" }} align="center">
                      Amount
                    </TableCell>
                    <TableCell style={{ width: "60px" }} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ExtraIncomeInputRow
                    extra={{}}
                    onSave={(extra) => saveExtra(extra)}
                  />
                  {form.extra_income &&
                    form.extra_income.map((extra, key) => (
                      <Fragment key={key}>
                        {extra.edit !== true ? (
                          <TableRow>
                            <TableCell align="center">{key + 1}</TableCell>
                            <TableCell>{extra.name}</TableCell>
                            <TableCell align="center">{extra.amount}</TableCell>
                            <TableCell className="controlTd" align="center">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => setEdit(key)}
                              >
                                <Icon>edit</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <ExtraIncomeInputRow
                            extra={extra}
                            onSave={(extra) => saveExtra(extra)}
                            onDelete={deleteExtra}
                          />
                        )}
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
}

function ExtraIncomeInputRow({ extra, onSave, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (extra) setForm(extra);
  }, [extra]);
  function save() {
    onSave(form);
  }
  return (
    <>
      {form && (
        <TableRow>
          <TableCell align="center" className="controlTd">
            {form.edit === true ? (
              <IconButton
                color="error"
                component="span"
                onClick={() => onDelete(form)}
              >
                <Icon>clear</Icon>
              </IconButton>
            ) : (
              <Icon style={{ color: "#999" }}>add</Icon>
            )}
          </TableCell>

          <TableCell className="inputTd">
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              value={form.name || ""}
            />
          </TableCell>
          <TableCell className="inputTd">
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e) => setForm({ ...form, amount: e.target.value })}
              value={form.amount || ""}
              type="number"
            />
          </TableCell>
          <TableCell className="controlTd" align="center">
            <IconButton
              color="primary"
              component="span"
              onClick={save}
              disabled={!form.name || form.name === ""}
            >
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
