import React, { Fragment, useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {
  GridSelect,
  GridInput,
  GridDatePicker,
} from "../components/InputComponents";
import FormExtraIncome from "../components/FormExtraIncome";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});
export default function FormWorkEmployee(props) {
  const classes = useStyles();
  const options = props.options;

  const [form, setForm] = useState();
  const [contractForm, setContractForm] = useState({});

  useEffect(() => {
    if (props.employee) {
      setForm(props.employee);
    }
  }, [props.employee]);

  async function saveContract(contract) {
    let Form = JSON.parse(JSON.stringify(form));
    if (!contract._id) {
      Form.contracts.push(contract);
    } else {
      let i = Form.contracts.findIndex((j) => j._id === contract._id);
      if (i > -1) Form.contracts[i] = contract;
    }

    setContractForm({});
    props.onSave(Form);
  }

  async function deleteContract(id) {
    if (window.confirm("Confirm to delete")) {
      let Form = JSON.parse(JSON.stringify(form));
      Form.contracts = Form.contracts.filter((j) => j._id !== id);
      setContractForm({});
      props.onSave(Form);
    }
  }
  async function getLastAssignId() {
    let id = "";
    let dt = new Date();
    dt = dt.getFullYear() + 543;
    dt = "/" + dt;
    let result = await axios.post(
      Conf.endpoint.app + "/query",
      {
        collection: "employees",
        limit: 1,
        keyword: { value: dt, fields: ["assign_id"] },
        sort: "-assign_id",
        select: "assign_id",
      },
      { headers: { Authorization: cookie.access_token } }
    );
    result = result.data.result;
    if (result[0] && result[0].assign_id !== "") {
      let arr = result[0].assign_id.split("/");
      id = arr[0].replace("ต.ก.", "").trim();
      id = parseInt(id) + 1;
    } else id = 1;

    id = "ต.ก." + String(id).padStart(3, "0") + dt;
    if (window.confirm("ตั้งค่าอัตโนมัติ : " + id)) {
      setForm({ ...form, assign_id: id });
    }
  }
  return (
    <div>
      {form && (
        <>
          <div className={classes.head1}>ข้อมูลการทำงาน</div>
          <Grid container spacing={2}>
            {/* <GridSelect
              grid={[12, 6, 4, 4]}
              label="Job"
              options={options.hr_job}
              onChange={(val) => setForm({ ...form, job: val })}
              value={form.job || "-"}
            /> */}
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="ตำแหน่ง"
              options={options.hr_position}
              onChange={(val) => setForm({ ...form, current_position: val })}
              value={form.current_position || "-"}
            />
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Division"
              options={options.hr_division}
              onChange={(val) => setForm({ ...form, division: val })}
              value={form.division || "-"}
            />

            <GridInput
              grid={[12, 6, 3, 3]}
              label="Work Promise No."
              placeholder="เลขที่ใบอนุญาตทำงาน"
              onChange={(val) => setForm({ ...form, work_promise_number: val })}
              value={form.work_promise_number}
            />

            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Work Promise Expire"
              placeholder="วันหมดอายุใบอนุญาตทำงาน"
              onChange={(val) => setForm({ ...form, work_promise_expire: val })}
              value={form.work_promise_expire || null}
            />

            {/* <GridInput
              grid={[12, 6, 4, 4]}
              label="Welfare"
              placeholder="สวัสดิการ"
              onChange={(val) => setForm({ ...form, welfare: val })}
              value={form.welfare}
            /> */}
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Welfare"
              options={options.hr_welfare}
              onChange={(val) => setForm({ ...form, welfare: val })}
              value={form.welfare || "-"}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Other welfare"
              placeholder="สวัสดิการอื่น ๆ"
              onChange={(val) => setForm({ ...form, other_welfare: val })}
              value={form.other_welfare}
            />
            {/* <GridInput
              grid={[12, 6, 4, 4]}
              label="Assign ID"
              placeholder="เลขที่บรรจุแต่งตั้ง"
              onChange={(val) => setForm({ ...form, assign_id: val })}
              value={form.assign_id}
            /> */}
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                label="Assign ID"
                placeholder="เลขที่บรรจุแต่งตั้ง"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(val) => setForm({ ...form, assign_id: val })}
                value={form.assign_id || ""}
                size="small"
                InputProps={{
                  style: { paddingRight: "0px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={getLastAssignId}
                      >
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Social security start"
              placeholder="วันที่เริ่มประกันสังคม"
              onChange={(val) =>
                setForm({ ...form, social_security_start_date: val })
              }
              value={form.social_security_start_date || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Assign Date"
              placeholder="วันที่บรรจุแต่งตั้ง"
              onChange={(val) => setForm({ ...form, assign_date: val })}
              value={form.assign_date || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Start work"
              placeholder="วันที่เริ่มงาน"
              onChange={(val) => setForm({ ...form, start_work: val })}
              value={form.start_work || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Resign work"
              placeholder="วันที่ลาออก"
              onChange={(val) => setForm({ ...form, resign_date: val })}
              value={form.resign_date || null}
            />
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Work status"
              options={options.hr_work_status}
              onChange={(val) => setForm({ ...form, work_status: val })}
              value={form.work_status || "-"}
            />
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ width: "250px" }}
                startIcon={<Icon>save</Icon>}
                onClick={() => props.onSave(form)}
              >
                Save Work Information
              </Button>
            </Grid>
          </Grid>
          <div className={classes.head1}>ข้อมูลสัญญา</div>
          <ContractForm
            contract={contractForm}
            options={options}
            onSave={saveContract}
          />
          <br />

          {form.contracts &&
            form.contracts.map((contract, key) => (
              <div
                key={key}
                style={
                  contract._id === contractForm._id
                    ? {
                        background: "#cfc",
                        paddingTop: "30px",
                        borderBottom: "1px solid #ccc",
                      }
                    : { paddingTop: "30px", borderBottom: "1px solid #ccc" }
                }
              >
                <ContractList
                  contract={contract}
                  selectedId={contractForm._id}
                  onSelected={() => setContractForm(contract)}
                  onDisselect={() => setContractForm({})}
                  onDelete={() => deleteContract(contract._id)}
                />
              </div>
            ))}
        </>
      )}
    </div>
  );
}

function ContractList({
  contract,
  selectedId,
  onSelected,
  onDisselect,
  onDelete,
}) {
  const labels = [
    { title: "เลขที่สัญญา", field: "contract_no" },
    { title: "ตำแหน่ง", field: "position" },
    { title: "แผนก", field: "division" },
    { title: "ประเภทสัญญา", field: "contract_type" },
    { title: "วันที่เริ่มสัญญา", field: "date_start" },
    { title: "วันที่สิ้นสุดสัญญา", field: "date_end" },
    { title: "รายได้", field: "income" },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {labels.map((label, key) => (
          <Fragment key={key}>
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              lg={3}
              className="bold"
              style={{ textAlign: "right", fontSize: ".8rem", padding: "5px" }}
            >
              {label.title} :
            </Grid>
            <Grid
              item
              xs={5}
              sm={6}
              md={7}
              lg={8}
              style={{ fontSize: ".8rem", padding: "3px" }}
            >
              {contract[label.field] || "-"}
            </Grid>
          </Fragment>
        ))}
        <Grid
          item
          xs={6}
          sm={5}
          md={4}
          lg={3}
          className="bold"
          style={{ textAlign: "right", fontSize: ".8rem", padding: "5px" }}
        >
          รายได้อื่น ๆ :
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
          md={7}
          lg={8}
          style={{ fontSize: ".8rem", padding: "3px", paddingTop: "6px" }}
        >
          {contract.extra_income &&
            contract.extra_income.map((income, key) => (
              <Grid container key={key}>
                <Grid item style={{ width: "200px" }}>
                  {key + 1}. {income.name}
                </Grid>
                <Grid item style={{ width: "100px", textAlign: "right" }}>
                  {income.amount} บาท
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }} className="controlTd">
          {contract._id === selectedId ? (
            <>
              <IconButton
                color="error"
                style={{ padding: "5px 5px" }}
                onClick={onDelete}
              >
                <Icon>clear</Icon>
              </IconButton>
              <IconButton
                color="success"
                style={{ padding: "5px 5px" }}
                onClick={onDisselect}
              >
                <Icon>undo</Icon>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                color="primary"
                style={{ padding: "5px 5px" }}
                onClick={onSelected}
              >
                <Icon>check_circle</Icon>
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function ContractForm({ contract, options, onSave }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (contract) setForm(contract);
  }, [contract]);
  function deleteExtra(extra) {
    let Form = JSON.parse(JSON.stringify(form));
    if (extra._id) {
      Form.extra_income = Form.extra_income.filter((j) => j._id !== extra._id);
      setForm(Form);
    } else if (extra.uuid) {
      Form.extra_income = Form.extra_income.filter(
        (j) => j.uuid !== extra.uuid
      );
      setForm(Form);
    }
  }
  return (
    <div>
      {form && (
        <>
          <Grid container spacing={2}>
            <GridInput
              grid={[12, 6, 4, 4]}
              label="Contract ID"
              placeholder="เลขที่สัญญา"
              onChange={(val) => setForm({ ...form, contract_no: val })}
              value={form.contract_no}
            />
            <GridSelect
              grid={[12, 6, 4, 4]}
              label="Division"
              options={options.hr_division}
              onChange={(val) => setForm({ ...form, division: val })}
              value={form.division || "-"}
            />
            <GridSelect
              grid={[12, 6, 4, 4]}
              label="Position"
              options={options.hr_position}
              onChange={(val) => setForm({ ...form, position: val })}
              value={form.position || "-"}
            />
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Contract type"
              options={options.hr_contract_type}
              onChange={(val) => setForm({ ...form, contract_type: val })}
              value={form.contract_type || "-"}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Date start"
              placeholder="วันที่เริ่มสัญญา"
              onChange={(val) => setForm({ ...form, date_start: val })}
              value={form.date_start || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Date end"
              placeholder="วันที่สิ้นสุดสัญญา"
              onChange={(val) => setForm({ ...form, date_end: val })}
              value={form.date_end || null}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Income"
              placeholder="รายได้"
              onChange={(val) => setForm({ ...form, income: val })}
              value={form.income}
              type="number"
            />

            <Grid item xs={12}>
              <div
                style={{
                  fontSize: ".9rem",
                  fontWeight: 500,
                  marginBottom: "10px",
                }}
              >
                รายได้อื่น ๆ
              </div>
              <FormExtraIncome
                contract={form}
                onSave={(extra) => setForm({ ...form, extra_income: extra })}
                onDelete={deleteExtra}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ width: "250px" }}
                startIcon={<Icon>save</Icon>}
                onClick={() => {
                  onSave(form);
                }}
              >
                Save Contract
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
/*
function ExtraIncomeTable({ contract, onSave, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (contract) {
      if (!contract.extra_income) contract.extra_income = [];
      for (let extra of contract.extra_income) {
        extra.uuid = uuid();
        extra.edit = false;
      }
      setForm(contract);
    }
  }, [contract]);
  function saveExtra(extra) {
    let newExtra = JSON.parse(JSON.stringify(form.extra_income || []));
    if (extra.uuid) {
      let i = newExtra.findIndex((j) => j.uuid === extra.uuid);
      extra.edit = false;
      newExtra[i] = extra;
    } else {
      extra.uuid = uuid();
      newExtra.push(extra);
    }
    onSave(newExtra);
  }
  function deleteExtra(id) {
    onDelete(id);
  }
  function setEdit(key) {
    let Form = JSON.parse(JSON.stringify(form));
    Form.extra_income[key].edit = true;
    setForm(Form);
  }
  return (
    <>
      {form && (
        <>
          <div style={{ fontSize: ".9rem", fontWeight: 500 }}>รายได้อื่น ๆ</div>
          <div>
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "60px" }} align="center">
                      No
                    </TableCell>
                    <TableCell align="center">Income Name</TableCell>
                    <TableCell style={{ width: "200px" }} align="center">
                      Amount
                    </TableCell>
                    <TableCell style={{ width: "60px" }} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ExtraIncomeInputRow
                    extra={{}}
                    onSave={(extra) => saveExtra(extra)}
                  />
                  {form.extra_income &&
                    form.extra_income.map((extra, key) => (
                      <Fragment key={key}>
                        {extra.edit !== true ? (
                          <TableRow>
                            <TableCell align="center">{key + 1}</TableCell>
                            <TableCell>{extra.name}</TableCell>
                            <TableCell align="center">{extra.amount}</TableCell>
                            <TableCell className="controlTd" align="center">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => setEdit(key)}
                              >
                                <Icon>edit</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <ExtraIncomeInputRow
                            extra={extra}
                            onSave={(extra) => saveExtra(extra)}
                            onDelete={deleteExtra}
                          />
                        )}
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
}

function ExtraIncomeInputRow({ extra, onSave, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (extra) setForm(extra);
  }, [extra]);
  function save() {
    onSave(form);
  }
  return (
    <>
      {form && (
        <TableRow>
          <TableCell align="center" className="controlTd">
            {form.edit === true ? (
              <IconButton
                color="error"
                component="span"
                onClick={() => onDelete(form._id)}
              >
                <Icon>clear</Icon>
              </IconButton>
            ) : (
              <Icon style={{ color: "#999" }}>add</Icon>
            )}
          </TableCell>

          <TableCell className="inputTd">
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              value={form.name || ""}
            />
          </TableCell>
          <TableCell className="inputTd">
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e) => setForm({ ...form, amount: e.target.value })}
              value={form.amount || ""}
              type="number"
            />
          </TableCell>
          <TableCell className="controlTd" align="center">
            <IconButton
              color="primary"
              component="span"
              onClick={save}
              disabled={!form.name || form.name === ""}
            >
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
*/
