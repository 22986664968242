import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PaginationSize({ size, page, count, changePage }) {
  return (
    <Stack spacing={2}>
      <Pagination
        count={Math.ceil(count) || 1}
        size={size || ""}
        page={page || 1}
        onChange={(e, val) => changePage(val)}
      />
    </Stack>
  );
}
