import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import ListEmployee from "../components/ListEmployee";
import Moment from "moment";
import {
  GridInput,
  InputText,
  GridDatePicker,
  TextDatePicker,
} from "../components/InputComponents";
import {
  checkPermission,
  fetchData,
  getFullname,
  updateData,
} from "../js/main";
import { useParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import axios from "axios";
import Conf from "../Conf";

function getEmployeeList(keyword, id) {
  return new Promise(async (resolve) => {
    let sendKeyword = null;
    let match = { work_status: "ปกติ" };
    if (keyword && keyword.trim() !== "") {
      sendKeyword = {
        value: keyword,
        fields: ["first_name", "last_name", "nickname", "division"],
      };
    }
    if (id) {
      match = { _id: id };
    }
    let employees = await fetchData({
      collection: "employees",
      keyword: sendKeyword,
      match: match,
      select: "prefix first_name middle_name last_name nickname division",
      limit: 1000,
      sort: { first_name: 1, last_name: 1 },
    });
    resolve(employees);
  });
}

export default function Person(props) {
  const { id } = useParams();
  const [profile, setProfile] = useState();
  const [employees, setEmployees] = useState();
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (props.profile) {
        let permission = await checkPermission(props.profile);
        if (permission === true) {
          setProfile(props.profile);
        }
      }
    })();
  }, [props.profile]);

  useEffect(() => {
    (async () => {
      if (profile) {
        let employees = await getEmployeeList();
        setEmployees(employees);
      }
    })();
  }, [profile]);
  async function goSearch() {
    let employees = await getEmployeeList(keyword);
    setEmployees(employees);
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          {id ? (
            <ContractInfo employee_id={id} />
          ) : (
            <>
              {employees && (
                <>
                  <InputText
                    label="Search"
                    onChange={(val) => setKeyword(val)}
                    onKeyUp={(e) => e.key === "Enter" && goSearch()}
                    value={keyword}
                    placeholder="ค้นหา..."
                  />
                  <br />
                  <br />
                  <ListEmployee
                    employees={employees.result}
                    skip={employees.skip || 0}
                    onSelect={(val) =>
                      navigate(
                        process.env.PUBLIC_URL + "/teacherinfo/" + val._id
                      )
                    }
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

function ContractInfo({ employee_id }) {
  const [form, setForm] = useState();
  const [employee, setEmployee] = useState();
  useEffect(() => {
    (async () => {
      let employee = await getEmployeeList(null, employee_id);
      if (employee.result[0]) {
        employee = employee.result[0];
        employee.fullname = await getFullname(employee);
        setEmployee(employee);

        let contract = await fetchData({
          collection: "hr_teacher_contracts",
          match: { employee_id },
          limit: 1,
        });
        if (contract.result && contract.result[0]) setForm(contract.result[0]);
        else setForm({});
      }
    })();
  }, [employee_id]);
  async function save() {
    let send = { ...form };
    send.employee_id = employee_id;
    let result = await updateData("put", "/contract", send);
    if (result && result._id) alert("Save completed");
  }
  return (
    <div>
      {form && (
        <>
          <h2 style={{ fontWeight: 400, margin: "0px auto 20px" }}>
            สัญญาครู - บุคลากรทางการศึกษา
          </h2>
          <Grid container spacing={2}>
            <GridInput
              grid={[12, 12, 4, 4]}
              label="Full name"
              placeholder="ชื่อบุคลากร"
              value={employee.fullname}
            />
            <GridInput
              grid={[12, 12, 4, 4]}
              label="Document code"
              placeholder="เลขที่คำสั่งแต่งตั้ง"
              onChange={(val) => setForm({ ...form, document_code: val })}
              value={form.document_code}
            />
            <GridInput
              grid={[12, 12, 4, 4]}
              label="Contract code"
              placeholder="เลขที่สัญญาจ้าง"
              onChange={(val) => setForm({ ...form, contract_code: val })}
              value={form.contract_code}
            />
            <GridDatePicker
              grid={[12, 12, 4, 4]}
              label="Document date"
              placeholder="วันที่แต่งตั้ง"
              onChange={(val) => setForm({ ...form, document_date: val })}
              value={form.document_date || null}
            />
            <GridDatePicker
              grid={[12, 12, 4, 4]}
              label="Start date"
              placeholder="สัญญาจ้างลงวันที่"
              onChange={(val) => setForm({ ...form, contract_start_date: val })}
              value={form.contract_start_date || null}
            />
            <GridDatePicker
              grid={[12, 12, 4, 4]}
              label="Receive date"
              placeholder="วันที่ให้สัญญา"
              onChange={(val) =>
                setForm({ ...form, contract_receive_date: val })
              }
              value={form.contract_receive_date || null}
            />
          </Grid>
          <h2 style={{ fontWeight: 400, margin: "40px auto 20px" }}>
            การถอดถอนครู - บุคลากรทางการศึกษา
          </h2>
          <Grid container spacing={2}>
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Document code"
              placeholder="เลขที่ถอดถอน"
              onChange={(val) =>
                setForm({ ...form, remove_document_code: val })
              }
              value={form.remove_document_code || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Remove date"
              placeholder="วันที่ถอดถอน"
              onChange={(val) => setForm({ ...form, remove_date: val })}
              value={form.remove_date || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Document date"
              placeholder="วันที่ตามหนังสือลาออก"
              onChange={(val) =>
                setForm({ ...form, remove_document_date: val })
              }
              value={form.remove_document_date || null}
            />
            <GridDatePicker
              grid={[12, 6, 3, 3]}
              label="Receive date"
              placeholder="วันที่ให้เอกสารถอดถอน"
              onChange={(val) => setForm({ ...form, remove_receive_date: val })}
              value={form.remove_receive_date || null}
            />
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                startIcon={<Icon>save</Icon>}
                style={{ width: "150px" }}
                onClick={() => save()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <br />
          <UploadDocuments
            employee_id={employee_id}
            uploads={form.uploads || []}
          />
        </>
      )}
    </div>
  );
}

function UploadDocuments({ employee_id, uploads }) {
  const filePackageSize = 300000;
  const [form, setForm] = useState({});
  const [files, setFiles] = useState(uploads);

  async function uploadFile() {
    return new Promise(async (resolve) => {
      let len = form.file.length;
      let file_id = null;
      for (let i = 0; i < len; i += filePackageSize) {
        let file_stream = form.file.substring(i, i + filePackageSize);
        let send = {
          file_id,
          file_stream,
        };
        let result = await axios.post(Conf.endpoint.upload, send);
        if (result.data.file_id) file_id = result.data.file_id;
      }
      resolve(file_id);
      resolve(true);
    });
  }
  async function save() {
    if (form.description) {
      let send = { ...form };
      if (form.file) {
        let fileUpload = await uploadFile();
        send.file_url = Conf.endpoint.file + "?id=" + fileUpload;
        delete send.file;
      }
      send.employee_id = employee_id;
      if (!send.document_date) send.document_date = new Date();

      console.log(send);
      let result = await updateData("put", "/contract/file", send);

      setFiles(result.uploads);
      setForm({});
    } else alert("กรุณากรอกข้อมูลให้ครบถ้วน");
  }
  async function remove(id) {
    if (window.confirm("ยืนยันการลบ")) {
      let result = await updateData("delete", "/contract/file/" + id);
      if (result.success === true) {
        let Files = [...files];
        Files = await Files.filter((j) => j._id !== id);
        console.log(Files);
        setFiles(Files);
      }
    }
  }
  function changeFile(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      console.log(file);
      reader.onloadend = function (e) {
        setForm({ ...form, file: reader.result });
      };
    }
  }
  async function download(url) {
    let file = await axios.get(url);
    let downloadLink = document.createElement("a");
    let filename = "download.pdf";
    downloadLink.href = file.data;
    downloadLink.download = filename;

    downloadLink.click();
    console.log(file.data);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "80px" }}>
                ลำดับ
              </TableCell>
              <TableCell align="center">รายละเอียด</TableCell>
              <TableCell align="center" style={{ width: "160px" }}>
                วันที่ออกเอกสาร
              </TableCell>
              <TableCell align="center" style={{ width: "100px" }}>
                ไฟล์
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <Icon style={{ color: "#777" }}>add</Icon>
              </TableCell>
              <TableCell className="inputTd">
                <InputText
                  onChange={(val) => setForm({ ...form, description: val })}
                  value={form.description || ""}
                  placeholder="Description"
                />
              </TableCell>
              <TableCell className="inputTd">
                <TextDatePicker
                  onChange={(val) => setForm({ ...form, document_date: val })}
                  value={form.document_date || new Date()}
                  placeholder="Date"
                />
              </TableCell>

              <TableCell className="controlTd" align="center">
                <label>
                  <input
                    accept="application/pdf,application/PDF"
                    type="file"
                    onChange={changeFile}
                    style={{ display: "none" }}
                  />
                  <IconButton color="success" component="span">
                    <Icon>upload</Icon>
                  </IconButton>
                </label>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={() => save()}
                >
                  <Icon>save</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
            {files &&
              files.map((file, key) => (
                <TableRow key={key}>
                  <TableCell align="center">{key + 1}</TableCell>
                  <TableCell>{file.description}</TableCell>
                  <TableCell align="center">
                    <div>{Moment(file.document_date).format("D MMM yyyy")}</div>
                  </TableCell>
                  <TableCell align="center" className="controlTd">
                    {file.file_url && (
                      <IconButton
                        color="success"
                        component="span"
                        onClick={() => download(file.file_url)}
                      >
                        <Icon>download</Icon>
                      </IconButton>
                    )}
                    <IconButton
                      color="error"
                      component="span"
                      onClick={() => remove(file._id)}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
