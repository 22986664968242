import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { GridSelect } from "../../components/InputComponents";
import Pagebar from "../../components/Pagebar";
import { getOptionTypes, fetchData, sortJSONDESC } from "../../js/main";

export default function Summary() {
  const [state, setState] = useState();
  const [options, setOptions] = useState({});
  const [employees, setEmployees] = useState();
  useEffect(() => {
    (async () => {
      let result = await getOptionTypes(["hr_contract_type"]);
      result.hr_contract_type.unshift({ label: "ทั้งหมด" });
      setOptions(result);
      let state = {
        contract_type: result.hr_contract_type[0]
          ? result.hr_contract_type[0].label
          : "-",
        limit: 100,
        skip: 0,
      };
      setState(state);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (state) {
        let match = { $and: [{ work_status: "ปกติ" }] };
        if (state.contract_type !== "ทั้งหมด")
          match["$and"].push({ contract_type: state.contract_type });
        let query = {
          match,
          collection: "employees",
          limit: state.limit,
          skip: state.skip || 0,
          sort: { first_name: 1, last_name: 1 },
        };
        let result = await fetchData(query);
        console.log(result);
        setEmployees(result);
      }
    })();
  }, [state]);

  return (
    <div>
      {state && employees && (
        <>
          <Grid container spacing={1}>
            <GridSelect
              grid={[12, 12, 6, 5]}
              options={options.hr_contract_type}
              label="Contract type"
              size="small"
              onChange={(val) =>
                setState({ ...state, contract_type: val, skip: 0 })
              }
              value={state.contract_type}
              disabled_empty={true}
            />
            <Grid item xs={12} sm={12} md={7} style={{ marginTop: "10px" }}>
              <Pagebar
                count={Math.floor(employees.count / state.limit) + 1}
                page={state.skip / state.limit + 1}
                changePage={(val) => {
                  setState({ ...state, skip: (val - 1) * state.limit });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              align="right"
              style={{ fontWeight: 500, marginTop: "10px" }}
            >
              Result : {employees.count} Person(s)
            </Grid>
          </Grid>
          <EmployeeTable employees={employees} />
        </>
      )}
    </div>
  );
}

function EmployeeTable({ employees }) {
  return (
    <div style={{ paddingTop: "10px" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "80px" }}>
                ลำดับ
              </TableCell>
              <TableCell align="center" style={{ width: "100px" }}>
                รหัส
              </TableCell>
              <TableCell align="center">ชื่อ - สกุล</TableCell>
              <TableCell align="center" style={{ width: "120px" }}>
                ชื่อเล่น
              </TableCell>
              <TableCell align="center">แผนก</TableCell>
              <TableCell align="center">ประเภทการบรรจุ</TableCell>
              <TableCell align="center" style={{ width: "120px" }}>
                วันที่ลาออก
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.count === 0 && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  align="center"
                  style={{ color: "#999", lineHeight: "60px" }}
                >
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
            {employees.result &&
              employees.result.map((emp, key) => (
                <TableRow key={key}>
                  <TableCell align="center">
                    {employees.skip + key + 1}
                  </TableCell>
                  <TableCell align="center">{emp.employee_id}</TableCell>
                  <TableCell>
                    {emp.prefix} {emp.first_name} {emp.last_name}
                  </TableCell>
                  <TableCell align="center">{emp.nickname}</TableCell>
                  <TableCell align="center">{emp.division}</TableCell>
                  <TableCell align="center">{emp.contract_type}</TableCell>
                  <TableCell align="center">{emp.resign_date}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
