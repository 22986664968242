import React, { Fragment, useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import ListEmployee from "../components/ListEmployee";
import DialogZip from "../components/DialogZip";
import FormPersonal from "../components/FormPersonal";
import FormWorkEmployee from "../components/FormWorkEmployee";
import FormEducation from "../components/FormEducation";
import FormSalary from "../components/FormSalary";
import FormGeneral from "../components/FormGeneral";

import { GridSelect, GridInput } from "../components/InputComponents";
import { checkPermission, getOptionTypes } from "../js/main";
import { prefixs } from "../const";
import Conf from "../Conf";
import { useParams, useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import Pagebar from "../components/Pagebar";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});

export default function Person(props) {
  const { id } = useParams();
  const [profile, setProfile] = useState();
  const [state, setState] = useState({ keyword: "", showAll: false });
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    (async () => {
      if (props.profile) {
        let permission = await checkPermission(props.profile);
        if (permission === true) {
          setProfile(props.profile);
        }
      }
    })();
  }, [props.profile]);
  return (
    <>
      {profile && state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <Grid container>
            <Grid item xs={12} md={7} lg={8} style={{ paddingTop: "15px" }}>
              <PageNameBox page={props.page} />
            </Grid>
            <Grid item xs={12} md={5} lg={4} style={{ paddingTop: "15px" }}>
              <div style={{ textAlign: "right" }}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyUp={(e) =>
                    e.key === "Enter" &&
                    setState({ ...state, keyword: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <FormControlLabel
                  onChange={(e) =>
                    setState({ ...state, showAll: e.target.checked })
                  }
                  control={<Checkbox checked={state.showAll} />}
                  label={<div>Show All</div>}
                />
              </div>
            </Grid>
          </Grid>
          {id ? (
            <PersonForm employee_id={id} />
          ) : (
            <PersonList searchState={state} />
          )}
        </div>
      )}
    </>
  );
}
function PersonForm(props) {
  // const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState({ ...props, tabNo: 0 });
  const [employee, setEmployee] = useState();
  const [options, setOptions] = useState();
  useEffect(() => {
    (async () => {
      if (state && state.employee_id) {
        let result = await axios.get(
          Conf.endpoint.app + "/employee/" + state.employee_id,
          { headers: { Authorization: cookie.access_token } }
        );
        if (result.data) {
          if (result.data.error) alert(result.data.error.message);
          else setEmployee(result.data);
        } else {
          console.log("New Employee");
          setEmployee({});
        }

        let options = await getOptionTypes([
          "hr_division",
          "hr_position",
          "hr_job",
          "hr_contract_type",
          "hr_employment_type",
          "hr_welfare",
          "hr_work_status",
          "hr_check_present_status",
          "hr_assessment_type",
          "hr_license_type",
          "hr_professional_type",
          "hr_education_level",
        ]);
        setOptions(options);
      }
    })();
  }, [state]);
  async function save(form) {
    let send = { ...form };
    if (send.child_no === "-") send.child_no = 0;
    let result = await axios.put(Conf.endpoint.app + "/employee", send, {
      headers: { Authorization: cookie.access_token },
    });
    console.log(result.data);
    if (result.data.error) alert(result.data.error.message);
    else {
      alert("Save completed");
      if (result.data._id !== id) {
        window.location = process.env.PUBLIC_URL + "/person/" + result.data._id;
      } else {
        setEmployee(result.data);
      }
    }
  }
  return (
    <div>
      {employee && options && (
        <>
          <Tabs
            value={state.tabNo}
            onChange={(val, val2) => setState({ ...state, tabNo: val2 })}
          >
            <Tab label={<div>ข้อมูลส่วนตัว</div>} />
            <Tab disabled={!employee._id} label={<div>ข้อมูลการทำงาน</div>} />
            <Tab disabled={!employee._id} label={<div>ข้อมูลที่อยู่</div>} />
            <Tab disabled={!employee._id} label={<div>การศึกษา</div>} />
            <Tab disabled={!employee._id} label={<div>ข้อมูลครอบครัว</div>} />
            <Tab disabled={!employee._id} label={<div>ข้อมูลรายได้</div>} />
            <Tab disabled={!employee._id} label={<div>ข้อมูลทั่วไป</div>} />
          </Tabs>
          <div>
            {
              {
                0: (
                  <FormPersonal
                    employee={employee}
                    options={options}
                    onSave={(form) => save(form)}
                  />
                ),
                1: (
                  <FormWorkEmployee
                    employee={employee}
                    options={options}
                    onSave={(form) => save(form)}
                  />
                ),
                2: (
                  <FormAddress
                    employee={employee}
                    onSave={(form) => save(form)}
                  />
                ),
                3: (
                  <FormEducation
                    employee={employee}
                    options={options}
                    onSave={(form) => save(form)}
                  />
                ),
                4: (
                  <FormFamily
                    employee={employee}
                    onSave={(form) => save(form)}
                  />
                ),
                5: (
                  <FormSalary
                    employee={employee}
                    onSave={(form) => save(form)}
                  />
                ),
                6: (
                  <FormGeneral
                    employee={employee}
                    onSave={(form) => save(form)}
                  />
                ),
              }[state.tabNo]
            }
          </div>
        </>
      )}
    </div>
  );
}
function FormFamily({ employee, onSave }) {
  const classes = useStyles();
  const [form, setForm] = useState();
  useEffect(() => {
    if (employee) setForm(employee);
  }, [employee]);
  return (
    <div>
      {form && (
        <>
          <div className={classes.head1}>ข้อมูลครอบครัว</div>

          <Grid container spacing={2}>
            <>
              <GridSelect
                grid={[12, 12, 2, 2]}
                label="Father prefix"
                options={[{ label: "นาย" }]}
                onChange={(val) => setForm({ ...form, father_prefix: val })}
                value={form.father_prefix || "-"}
              />
              <GridInput
                grid={[12, 12, 5, 5]}
                label="Father firstname"
                placeholder="ชื่อบิดา"
                onChange={(val) => setForm({ ...form, father_first_name: val })}
                value={form.father_first_name}
              />
              <GridInput
                grid={[12, 12, 5, 5]}
                label="Father lastname"
                placeholder="นามสกุลบิดา"
                onChange={(val) => setForm({ ...form, father_last_name: val })}
                value={form.father_last_name}
              />
              <GridInput
                grid={[12, 12, 12, 12]}
                label="Father job"
                placeholder="อาชีพบิดา"
                onChange={(val) => setForm({ ...form, father_job: val })}
                value={form.father_job}
              />
              <GridSelect
                grid={[12, 12, 2, 2]}
                label="Mother prefix"
                options={[{ label: "นางสาว" }, { label: "นาง" }]}
                onChange={(val) => setForm({ ...form, mother_prefix: val })}
                value={form.mother_prefix || "-"}
              />
              <GridInput
                grid={[12, 12, 5, 5]}
                label="Mother firstname"
                placeholder="ชื่อมารดา"
                onChange={(val) => setForm({ ...form, mother_first_name: val })}
                value={form.mother_first_name}
              />
              <GridInput
                grid={[12, 12, 5, 5]}
                label="Mother lastname"
                placeholder="นามสกุลมารดา"
                onChange={(val) => setForm({ ...form, mother_last_name: val })}
                value={form.mother_last_name}
              />
              <GridInput
                grid={[12, 12, 12, 12]}
                label="Mother job"
                placeholder="อาชีพมารดา"
                onChange={(val) => setForm({ ...form, mother_job: val })}
                value={form.mother_job}
              />
            </>
            <>
              <GridInput
                grid={[12, 4, 4, 4]}
                label="Brethren number"
                type="number"
                placeholder="จำนวนพี่น้อง"
                onChange={(val) => setForm({ ...form, brethren_no: val })}
                value={form.brethren_no}
              />
              <GridInput
                grid={[12, 4, 4, 4]}
                label="Brethren of"
                type="number"
                placeholder="เป็นบุตรคนที่"
                onChange={(val) => setForm({ ...form, brethren_of: val })}
                value={form.brethren_of}
              />
              <GridSelect
                grid={[12, 4, 4, 4]}
                label="Married status"
                options={[
                  { label: "โสด" },
                  { label: "สมรส" },
                  { label: "หย่าร้าง" },
                ]}
                onChange={(val) => setForm({ ...form, married_status: val })}
                value={form.married_status || "-"}
              />
            </>
          </Grid>

          <div className={classes.head1}>ข้อมูลคู่สมรส</div>
          <Grid container spacing={2}>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Spouse prefix"
              options={prefixs || []}
              onChange={(val) => setForm({ ...form, spouse_prefix: val })}
              value={form.spouse_prefix || "-"}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Spouse firstname"
              placeholder="ชื่อคู่สมรส"
              onChange={(val) => setForm({ ...form, spouse_first_name: val })}
              value={form.spouse_first_name}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Spouse middlename"
              placeholder="ชื่อกลางคู่สมรส"
              onChange={(val) => setForm({ ...form, spouse_middle_name: val })}
              value={form.spouse_middle_name}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Spouse lastname"
              placeholder="นามสกุลคู่สมรส"
              onChange={(val) => setForm({ ...form, spouse_last_name: val })}
              value={form.spouse_last_name}
            />
            <GridInput
              grid={[12, 6, 6, 6]}
              label="Spouse job"
              placeholder="อาชีพสมรส"
              onChange={(val) => setForm({ ...form, spouse_job: val })}
              value={form.spouse_job}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Spouse phone"
              placeholder="เบอร์โทรศัพท์คู่สมรส"
              onChange={(val) => setForm({ ...form, spouse_phone: val })}
              value={form.spouse_phone}
              type="number"
            />
            {/* <GridInput
              grid={[12, 6, 3, 3]}
              label="Children number"
              placeholder="จำนวนบุตร"
              onChange={(val) => setForm({ ...form, child_no: val })}
              value={form.child_no}
            /> */}

            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Children number"
              options={[
                { label: "1" },
                { label: "2" },
                { label: "3" },
                { label: "4" },
                { label: "5" },
              ]}
              onChange={(val) => setForm({ ...form, child_no: val })}
              value={form.child_no || "0"}
            />
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => onSave(form)}
                style={{ width: "150px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

function FormAddress({ employee, onSave }) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState({ open: false });
  const [form, setForm] = useState();
  useEffect(() => {
    if (employee) setForm(employee);
  }, [employee]);
  function selectArea(area) {
    let field = dialogState.field;
    setForm({
      ...form,
      [field + "area"]: area.area,
      [field + "district"]: area.district,
      [field + "province"]: area.province,
    });
    setDialogState({ open: false });
  }
  function copyAddr() {
    let Form = JSON.parse(JSON.stringify(form));
    setForm({
      ...form,
      current_village: Form.village,
      current_house_no: Form.house_no,
      current_moo: Form.moo,
      current_soi: Form.soi,
      current_street: Form.street,
      current_area: Form.area,
      current_district: Form.district,
      current_province: Form.province,
      current_zip_code: Form.zip_code,
    });
  }
  function save() {
    onSave(form);
  }
  return (
    <div>
      {form && (
        <>
          <DialogZip
            state={dialogState}
            onClose={() => setDialogState({ open: false })}
            onSelect={selectArea}
          />
          <div className={classes.head1}>ข้อมูลที่อยู่ตามทะเบียนบ้าน</div>
          <AddressForm
            employee={form}
            field=""
            updateForm={(val) => setForm({ ...form, ...val })}
            selectZip={() =>
              setDialogState({ open: true, code: form.zip_code, field: "" })
            }
          />
          <Grid container>
            <Grid item xs={9}>
              <div className={classes.head1}>ข้อมูลที่อยู่ปัจจุบัน</div>
            </Grid>
            <Grid item xs={3} align="right" style={{ paddingTop: "20px" }}>
              <IconButton color="primary" onClick={copyAddr}>
                <Icon>file_copy</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <AddressForm
            employee={form}
            field="current_"
            updateForm={(val) => setForm({ ...form, ...val })}
            selectZip={() =>
              setDialogState({
                open: true,
                code: form.current_zip_code,
                field: "current_",
              })
            }
          />
          <div style={{ paddingTop: "20px", textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={save}
              style={{ width: "150px" }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function AddressForm({ field, employee, updateForm, selectZip }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (employee) setForm(employee);
  }, [employee]);
  return (
    <div>
      {form && (
        <Grid container spacing={2}>
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Village/Condo/Apartment"
            placeholder="ชื่อหมู่บ้าน/คอนโด/หอพัก"
            onChange={(val) =>
              updateForm({ ...form, [field + "village"]: val })
            }
            value={form[field + "village"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="House No."
            placeholder="บ้านเลขที่"
            onChange={(val) =>
              updateForm({ ...form, [field + "house_no"]: val })
            }
            value={form[field + "house_no"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Moo"
            placeholder="หมู่"
            onChange={(val) => updateForm({ ...form, [field + "moo"]: val })}
            value={form[field + "moo"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Soi"
            placeholder="ซอย"
            onChange={(val) => updateForm({ ...form, [field + "soi"]: val })}
            value={form[field + "soi"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Street"
            placeholder="ถนน"
            onChange={(val) => updateForm({ ...form, [field + "street"]: val })}
            value={form[field + "street"]}
          />
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              label="Zip code"
              placeholder="รหัสไปรษณีย์"
              size="small"
              fullWidth
              value={form[field + "zip_code"]}
              InputLabelProps={{ shrink: true }}
              onKeyUp={(e) =>
                e.key === "Enter" && selectZip(form[field + "zip_code"])
              }
              onChange={(e) =>
                updateForm({ ...form, [field + "zip_code"]: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => selectZip(form[field + "zip_code"])}
                  >
                    <IconButton>
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Area"
            placeholder="ตำบล"
            onChange={(val) => updateForm({ ...form, [field + "area"]: val })}
            value={form[field + "area"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="District"
            placeholder="อำเภอ"
            onChange={(val) =>
              updateForm({ ...form, [field + "district"]: val })
            }
            value={form[field + "district"]}
          />
          <GridInput
            grid={[12, 6, 4, 4]}
            label="Province"
            placeholder="จังหวัด"
            onChange={(val) =>
              updateForm({ ...form, [field + "province"]: val })
            }
            value={form[field + "province"]}
          />
        </Grid>
      )}
    </div>
  );
}

function PersonList({ searchState }) {
  const navigate = useNavigate();
  const [state, setState] = useState();
  const [employees, setEmployees] = useState();
  useEffect(() => {
    (async () => {
      if (searchState) {
        let newState = JSON.parse(JSON.stringify(searchState));
        newState.skip = 0;
        newState.limit = 50;
        setState(newState);
      }
    })();
  }, [searchState]);

  useEffect(() => {
    (async () => {
      if (state) {
        let match = {};
        if (state.showAll === false) match = { work_status: "ปกติ" };
        let keyword = null;
        if (state.keyword && state.keyword.trim() !== "") {
          keyword = {
            value: state.keyword,
            fields: ["first_name", "last_name", "nickname", "division"],
          };
        }
        let result = await axios.post(
          Conf.endpoint.app + "/query",
          {
            collection: "employees",
            match: match,
            sort: { first_name: 1, last_name: 1 },
            skip: state.skip,
            limit: state.limit,
            keyword: keyword,
            select: "prefix first_name middle_name last_name nickname division",
          },
          { headers: { Authorization: cookie.access_token } }
        );
        setEmployees(result.data);
      }
    })();
  }, [state]);
  return (
    <div>
      {employees && state && (
        <>
          <div style={{ paddingBottom: "15px" }}>
            <Pagebar
              count={Math.floor(employees.count / state.limit) + 1}
              page={state.skip / state.limit + 1}
              changePage={(val) => {
                setState({ ...state, skip: (val - 1) * state.limit });
              }}
            />
          </div>
          <ListEmployee
            employees={employees.result}
            skip={state.skip}
            onSelect={(val) =>
              navigate(process.env.PUBLIC_URL + "/person/" + val._id)
            }
          />
          <br />
          <Button
            variant="contained"
            startIcon={<Icon>add</Icon>}
            onClick={() => navigate(process.env.PUBLIC_URL + "/person/new")}
          >
            New Employee
          </Button>
        </>
      )}
    </div>
  );
}
