import React from "react";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

export default function PageNameBox(props) {
  const { page } = props;
  const styles = {
    text: {
      padding: "0px 0px",
      margin: "0px",
      fontSize: page.textSize || "2rem",
    },
    icon: {
      fontSize: page.iconSize || "2rem",
    },
    grid: page.gridStyle || {
      width: "45px",
      paddingTop: "12px",
    },
  };
  return (
    <div>
      <Grid container>
        <Grid item style={styles.grid}>
          <Icon style={styles.icon}>{page.icon}</Icon>
        </Grid>
        <Grid item>
          <h1 style={styles.text}>{page.label}</h1>
        </Grid>
      </Grid>
    </div>
  );
}
