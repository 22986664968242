import React, { Fragment, useEffect, useState } from "react";

import { InputText, TextDatePicker } from "./InputComponents";
import FormExtraIncome from "./FormExtraIncome";

import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});

export default function FormSalary(props) {
  const classes = useStyles();
  const [employee, setEmployee] = useState();
  const [state, setState] = useState({ selectedSalary: {}, open: false });
  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee]);

  /*
  function addTable() {
    if (form.salary_change_date && form.salary) {
      let emp = JSON.parse(JSON.stringify(employee));
      if (!emp.salary_history) emp.salary_history = [];
      if (!form.history_id) form.history_id = uuid();
      emp.salary_history.push(form);
      props.onSave(emp);
      setForm({});
    }
  }
  function removeTable(id) {
    if (window.confirm("Confirm to delete")) {
      let emp = JSON.parse(JSON.stringify(employee));
      emp.salary_history = emp.salary_history.filter(
        (j) => j.history_id !== id
      );
      props.onSave(emp);
      setForm({});
    }
  }
  */
  function deleteSalary(id) {
    if (window.confirm("Confirm to delete")) {
      let emp = JSON.parse(JSON.stringify(employee));
      emp.salary_history = emp.salary_history.filter((j) => j._id !== id);
      props.onSave(emp);
    }
  }
  function selectSalary(id, selected) {
    let i = employee.salary_history.findIndex((j) => j._id === id);

    if (i > -1) {
      let Emp = JSON.parse(JSON.stringify(employee));
      Emp.salary_history[i].edit = selected;
      setEmployee(Emp);
    }
  }
  function sumExtra(salary) {
    let r = 0;
    if (salary && salary.extra_income) {
      for (let extra of salary.extra_income) {
        r += extra.amount;
      }
    }
    return r;
  }
  function saveSalary(salary) {
    if (salary.salary_change_date && salary.salary) {
      let emp = JSON.parse(JSON.stringify(employee));
      if (!emp.salary_history) emp.salary_history = [];
      if (!salary._id) {
        emp.salary_history.push(salary);
        props.onSave(emp);
        setState({ ...state, selectedSalary: {} });
      } else {
        let i = emp.salary_history.findIndex((j) => j._id === salary._id);
        if (i > -1) {
          emp.salary_history[i] = salary;
          props.onSave(emp);
        }
      }
    }
  }
  function openExtra(salary) {
    if (!salary._id) {
      if (!salary.openExtra) salary.openExtra = true;
      else salary.openExtra = !salary.openExtra;
      setState({ ...state, selectedSalary: salary, open: true });
    } else {
      let i = employee.salary_history.findIndex((j) => j._id === salary._id);
      if (i > -1) {
        let emp = JSON.parse(JSON.stringify(employee));
        emp.salary_history[i].openExtra = !emp.salary_history[i].openExtra;
        setEmployee(emp);
      }
    }
  }
  function saveExtra(extra, salary_id) {
    if (!salary_id) {
      let salary = JSON.parse(JSON.stringify(state.selectedSalary));
      salary.extra_income = extra;
      setState({ ...state, selectedSalary: salary, open: false });
    } else {
      let i = employee.salary_history.findIndex((j) => j._id === salary_id);
      if (i > -1) {
        let emp = JSON.parse(JSON.stringify(employee));
        emp.salary_history[i].extra_income = extra;
        setEmployee(emp);
      }
    }
  }
  function deleteExtra(extra, salary) {
    if (!salary._id) {
      let Salary = JSON.parse(JSON.stringify(salary));
      Salary.extra_income = Salary.extra_income.filter(
        (j) => j.uuid !== extra.uuid
      );
      setState({ ...state, selectedSalary: Salary });
    } else {
      let emp = JSON.parse(JSON.stringify(employee));
      let i = employee.salary_history.findIndex((j) => j._id === salary._id);
      if (i > -1) {
        let extra_income = JSON.parse(
          JSON.stringify(emp.salary_history[i].extra_income)
        );
        if (extra._id)
          extra_income = extra_income.filter((j) => j._id !== extra._id);
        else if (extra.uid)
          extra_income = extra_income.filter((j) => j.uid !== extra.uid);
        emp.salary_history[i].extra_income = extra_income;
        console.log(extra_income, extra._id);
        setEmployee(emp);
      }
    }
  }
  return (
    <div>
      {employee && (
        <>
          <div className={classes.head1}>ประวัติการขึ้นเงินเดือน</div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "100px" }}
                  >
                    ลำดับ
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "30%" }}
                  >
                    วันที่ขึ้นเงินเดือน
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "30%" }}
                  >
                    เงินเดือน
                  </TableCell>
                  <TableCell className="th" align="center">
                    รายได้อื่น ๆ
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "150px" }}
                  >
                    การปรับขึ้นเงินเดือน
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FormEditSalary
                  salary={state.selectedSalary}
                  onSave={saveSalary}
                  onSaveExtra={saveExtra}
                  onOpenDialog={openExtra}
                  onDeleteExtra={deleteExtra}
                />
                {employee.salary_history &&
                  employee.salary_history.map((history, key) => (
                    <Fragment key={key}>
                      {history.edit !== true ? (
                        <TableRow>
                          <TableCell className="td" align="center">
                            {key + 1}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {history.salary_change_date}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {parseFloat(history.salary).toLocaleString()}
                          </TableCell>
                          <TableCell align="center" className="inputTd">
                            {sumExtra(history)}
                          </TableCell>
                          <TableCell className="controlTd" align="center">
                            <IconButton
                              color="error"
                              onClick={() => selectSalary(history._id, true)}
                            >
                              <Icon style={{ fontSize: "1.8rem" }}>edit</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <FormEditSalary
                          salary={history}
                          onUnselect={() => selectSalary(history._id, false)}
                          onSave={saveSalary}
                          onSaveExtra={(extra) => saveExtra(extra, history._id)}
                          onOpenDialog={openExtra}
                          onDeleteExtra={deleteExtra}
                          onDelete={() => deleteSalary(history._id)}
                          //   salary={state.selectedSalary}
                          //   onSave={saveSalary}
                          //   onSaveExtra={saveExtra}
                          //   onOpenDialog={openExtra}
                          //   onDeleteExtra={deleteExtra}
                        />
                      )}
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

function FormEditSalary({
  salary,
  onUnselect,
  onSave,
  onOpenDialog,
  onDelete,
  onDeleteExtra,
  onSaveExtra,
}) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (salary) setForm(salary);
  }, [salary]);

  function sumExtra() {
    let r = 0;
    if (form && form.extra_income) {
      r = form.extra_income.reduce(
        (previousValue, currentValue) =>
          parseInt(previousValue) + parseInt(currentValue.amount),
        0
      );
    }
    return r;
  }
  return (
    <>
      {form && (
        <>
          <TableRow>
            <TableCell align="center" className="controlTd">
              {form._id ? (
                <IconButton color="error" onClick={onDelete}>
                  <Icon>clear</Icon>
                </IconButton>
              ) : (
                <Icon>add</Icon>
              )}
            </TableCell>
            <TableCell align="center" className="inputTd">
              <TextDatePicker
                placeholder="วันที่ขึ้นเงินเดือน"
                onChange={(val) =>
                  setForm({ ...form, salary_change_date: val })
                }
                value={form.salary_change_date || null}
              />
            </TableCell>
            <TableCell align="center" className="inputTd">
              <InputText
                placeholder="เงินเดือน"
                type="number"
                onChange={(val) => setForm({ ...form, salary: val })}
                value={form.salary}
              />
            </TableCell>

            <TableCell
              align="center"
              className="inputTd"
              onClick={() => onOpenDialog(form)}
            >
              <Button>{sumExtra()}</Button>
            </TableCell>
            <TableCell align="center" className="controlTd">
              {form._id && (
                <IconButton color="success" onClick={onUnselect}>
                  <Icon style={{ fontSize: "1.8rem" }}>undo</Icon>
                </IconButton>
              )}

              <IconButton color="primary" onClick={() => onSave(form)}>
                <Icon style={{ fontSize: "1.8rem" }}>save</Icon>
              </IconButton>
            </TableCell>
          </TableRow>
          {salary.openExtra === true && (
            <TableRow>
              <TableCell
                colSpan={5}
                align="center"
                className="controlTd"
                style={{ background: "#E91E63" }}
              >
                <div style={{ margin: "0px 0px 3px 0px", background: "#fff" }}>
                  <FormExtraIncome
                    contract={form}
                    onSave={onSaveExtra}
                    onDelete={(extra) => onDeleteExtra(extra, form)}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
}

// function DialogExtra({ salary, open, onClose, onSave }) {
//   const [form, setForm] = useState();
//   useEffect(() => {
//     if (salary) {
//       setForm(salary);
//     }
//   }, [salary]);

//   function save() {
//     console.log(form);
//     onSave(form);
//   }
//   return (
//     <div>
//       <Dialog
//         open={open}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">รายได้อื่น ๆ</DialogTitle>
//         <DialogContent>
//           {JSON.stringify(salary)}
//           <FormExtraIncome
//             contract={form}
//             onSave={(extra) => setForm({ ...form, extra_income: extra })}
//             // onDelete={deleteExtra}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={onClose}>Cancel</Button>
//           <Button onClick={save}>Save</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
