export const genders = [
  { value: "หญิง", label: "หญิง" },
  { value: "ชาย", label: "ชาย" },
];
export const jobs = [
  { label: "ข้าราชการ" },
  { label: "เกษตรกร" },
  { label: "พนักงานราชการ" },
  { label: "พนักงานบริษัทฯ" },
  { label: "พนักงานรัฐวิสาหกิจ" },
  { label: "ลูกจ้าง" },
  { label: "ธุรกิจส่วนตัว" },
  { label: "ค้าขาย" },
  { label: "อื่น ๆ" },
];

export const prefixs = [
  { label: "นาย" },
  { label: "นาง" },
  { label: "นางสาว" },
];
export const prefixs_alt = [
  { label: "MR." },
  { label: "MISS" },
  { label: "MRS." },
];
export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const family_statuses = [
  { label: "อยู่" },
  { label: "ร่วมกัน" },
  { label: "หย่าร้าง" },
  { label: "แยกกันอยู่" },
  { label: "บิดาเสียชีวิต" },
  { label: "มารดาเสียชีวิต" },
  { label: "บิดาและมารดาเสียชีวิต" },
];

export const weekDays = [
  { name: "Sunday", abbrev: "Sun", value: 0 },
  { name: "Monday", abbrev: "Mon", value: 1 },
  { name: "Tuesday", abbrev: "Tue", value: 2 },
  { name: "Wednesday", abbrev: "Wed", value: 3 },
  { name: "Thursday", abbrev: "Thu", value: 4 },
  { name: "Friday", abbrev: "Fri", value: 5 },
  { name: "Saturday", abbrev: "Sat", value: 6 },
];
