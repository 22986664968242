import React, { Fragment, useEffect, useState } from "react";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { SelectBox } from "../components/InputComponents";
import {
  checkPermission,
  getDivisionStaff,
  getFullname,
  getOptionTypes,
  updateData,
  fetchData,
} from "../js/main";

export default function AbsendDay({ profile, page }) {
  const [options, setOptions] = useState();
  const [form, setForm] = useState({ option: "hr_division" });
  const [types, setTypes] = useState([
    { value: "hr_division", label: "แผนก" },
    { value: "hr_education_level", label: "ระดับการศึกษา" },
    { value: "hr_job", label: "หน้าที่" },
    { value: "hr_position", label: "ตำแหน่ง" },
    { value: "hr_professional_type", label: "ประเภทใบประกอบวิชาชีพ" },
    { value: "hr_welfare", label: "สวัสดีการ" },
    { value: "hr_work_status", label: "สถานะการทำงาน" },
  ]);

  useEffect(() => {
    (async () => {
      if (profile) {
        let permission = await checkPermission(profile);
        if (permission === true) {
          let Options = [];
          for (let type of types) {
            Options.push(type.value);
          }
          Options = await getOptionTypes(Options);
          setOptions(Options);
        }
      }
    })();
  }, [profile, types]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          <SelectBox
            label="เลือกผู้ถูกประเมิน"
            options={types}
            onChange={(val) => {
              setForm({ ...form, option: val });
            }}
            value={form.option}
            disabled_empty={true}
          />
          <br />
          <br />
          {options && (
            <OptionTable value={form.option} options={options[form.option]} />
          )}
        </div>
      )}
    </>
  );
}

function OptionTable({ value, options }) {
  const [form, setForm] = useState();
  useEffect(() => {
    setForm(options);
  }, [options]);

  function selectOption(key) {
    let Form = [...form];
    if (!Form[key].edit) Form[key].edit = true;
    else Form[key].edit = !Form[key].edit;
    setForm(Form);
  }

  async function save(option) {
    let data = {
      _id: option._id || null,
      type: value,
      label: option.label,
    };
    let result = await updateData("put", "/admin/option", data);
    let Form = [...form];
    if (option._id) {
      let i = Form.findIndex((j) => j._id === option._id);
      console.log(i, option._id);
      if (i > -1) {
        Form[i] = result;
      }
      Form[i].edit = false;
    } else {
      console.log(result);
      Form.unshift(result);
    }
    setForm(Form);
  }
  async function remove(id) {
    if (window.confirm("Confirm to remove")) {
      await updateData("delete", "/admin/option/" + id);
      let Form = [...form];
      Form = Form.filter((j) => j._id !== id);
      setForm(Form);
    }
  }
  return (
    <div>
      {form && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className="th" style={{ width: "80px" }}>
                  No
                </TableCell>
                <TableCell className="th">Label</TableCell>
                <TableCell className="th" style={{ width: "120px" }}>
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <EditRow option={{}} onSave={save} />
              {form.map((option, key) => (
                <Fragment key={key}>
                  {option.edit !== true ? (
                    <TableRow>
                      <TableCell align="center">{key + 1}</TableCell>
                      <TableCell>{option.label}</TableCell>
                      <TableCell className="controlTd" align="center">
                        <IconButton
                          color="primary"
                          onClick={() => selectOption(key)}
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <EditRow
                      option={option}
                      onUndo={() => selectOption(key)}
                      onSave={save}
                      onDelete={() => remove(option._id)}
                    />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function EditRow({ option, onUndo, onSave, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    setForm(option);
  }, [option]);

  return (
    <TableRow>
      {form && (
        <>
          <TableCell align="center" className="controlTd">
            {option._id ? (
              <IconButton color="error" onClick={onDelete}>
                <Icon>clear</Icon>
              </IconButton>
            ) : (
              <Icon style={{ color: "#999" }}>add</Icon>
            )}
          </TableCell>
          <TableCell className="inputTd">
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={form.label || ""}
              onChange={(e) => setForm({ ...form, label: e.target.value })}
            />
          </TableCell>
          <TableCell className="controlTd" align="center">
            {option._id && (
              <IconButton color="success" onClick={onUndo}>
                <Icon>undo</Icon>
              </IconButton>
            )}
            <IconButton color="primary" onClick={() => onSave(form)}>
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
