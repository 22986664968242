import React, { Fragment, useEffect, useState } from "react";
import TableTrain from "./TableTrain";

import {
  GridSelect,
  GridInput,
  GridDatePicker,
  //   InputText,
  //   TextDatePicker,
} from "../components/InputComponents";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import uuid from "react-uuid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  head1: {
    color: "#333",
    padding: "25px 0px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
});
export default function FormEducation(props) {
  const classes = useStyles();
  const { options } = props;
  const [form, setForm] = useState({});
  const [employee, setEmployee] = useState();
  const [historyId, setHistoryId] = useState();
  const [licenseForm, setLicenseForm] = useState({});
  const [selectLicenseId, setSelectLicenseId] = useState();

  useEffect(() => {
    if (props.employee) {
      for (let education_history of props.employee.education_history) {
        education_history.history_id = uuid();
      }
      setEmployee(props.employee);
    }
  }, [props]);
  async function save() {
    let emp = JSON.parse(JSON.stringify(employee));
    if (!emp.education_history) emp.education_history = [];
    if (!form.history_id) {
      form.history_id = uuid();
      emp.education_history.push(form);
    } else {
      let i = await emp.education_history.findIndex(
        (j) => j.history_id === form.history_id
      );
      emp.education_history[i] = form;
    }

    props.onSave(emp);
    setForm({});
    setHistoryId(null);
  }
  function saveLicense() {
    let emp = JSON.parse(JSON.stringify(employee));
    if (!licenseForm._id) {
      emp.licenses.push(licenseForm);
      props.onSave(emp);
    } else {
      let i = emp.licenses.findIndex((j) => j._id === licenseForm._id);
      if (i > -1) {
        emp.licenses[i] = licenseForm;
        props.onSave(emp);
      }
    }
    setLicenseForm({});
    setSelectLicenseId(null);
  }
  async function deleteLicense() {
    if (window.confirm("Confirm to delete")) {
      let emp = JSON.parse(JSON.stringify(employee));
      emp.licenses = await emp.licenses.filter(
        (j) => j._id !== licenseForm._id
      );
      props.onSave(emp);
      setLicenseForm({});
      setSelectLicenseId(null);
    }
  }

  function deleteHistory(id) {
    if (window.confirm("Confirm to delete")) {
      let emp = JSON.parse(JSON.stringify(employee));
      emp.education_history = emp.education_history.filter(
        (j) => j.history_id !== id
      );
      props.onSave(emp);
      setForm({});
      setHistoryId(null);
    }
  }
  return (
    <div>
      {employee && (
        <>
          <div className={classes.head1}>
            ข้อมูลใบประกอบวิชาชีพ - ใบผ่อนผัน ผู้บริหาร / ครู
          </div>
          <Grid container spacing={2}>
            <GridSelect
              grid={[12, 6, 6, 6]}
              label="License type"
              options={options.hr_professional_type}
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, qualified_name: val })
              }
              value={licenseForm.qualified_name || "-"}
            />
            <GridInput
              grid={[12, 6, 6, 6]}
              label="License No."
              placeholder="เลขใบประกอบวิชาชีพ"
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, license_no: val })
              }
              value={licenseForm.license_no}
            />

            <GridDatePicker
              grid={[12, 6, 4, 4]}
              label="License start"
              placeholder="วันที่ออกบัตร"
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, license_start: val })
              }
              value={licenseForm.license_start || null}
            />
            <GridDatePicker
              grid={[12, 6, 4, 4]}
              label="License expire"
              placeholder="วันหมดอายุ"
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, license_expire: val })
              }
              value={licenseForm.license_expire || null}
            />

            <GridSelect
              grid={[12, 6, 4, 4]}
              label="Time of renew"
              options={[
                { label: "ครั้งที่ 1" },
                { label: "ครั้งที่ 2" },
                { label: "ครั้งที่ 3" },
                { label: "ครั้งที่ 4" },
              ]}
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, license_renew: val })
              }
              value={licenseForm.license_renew || "-"}
            />
            {/* <GridInput
              grid={[12, 6, 4, 4]}
              label="Time of renew"
              placeholder="ครั้งที่ผ่อนผัน"
              onChange={(val) =>
                setLicenseForm({ ...licenseForm, license_renew: val })
              }
              value={licenseForm.license_renew}
            /> */}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={saveLicense}
                style={{ width: "150px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <br />
          {employee.licenses.map((license, key) => (
            <div
              key={key}
              style={
                license._id === selectLicenseId
                  ? {
                      background: "#dfd",
                      padding: "30px 10px",
                      paddingBottom: "10px",
                      borderBottom: "1px solid #ccc",
                    }
                  : {
                      padding: "30px 10px",
                      paddingBottom: "10px",
                      borderBottom: "1px solid #ccc",
                    }
              }
            >
              <LicenseDetail
                license={license}
                onSelected={() => {
                  setSelectLicenseId(license._id);
                  setLicenseForm(license);
                }}
                onDisselect={() => {
                  setSelectLicenseId(null);
                  setLicenseForm({});
                }}
                onDelete={deleteLicense}
                selected_id={selectLicenseId}
              />
            </div>
          ))}
          <div className={classes.head1}>ประวัติการศึกษา</div>

          <EducationHistory
            options={options}
            history={form}
            updateItem={(item) => setForm({ ...form, ...item })}
            addHistory={save}
          />
          <br />
          {employee.education_history &&
            employee.education_history.map((history, key) => (
              <div
                key={key}
                style={
                  history.history_id === historyId ? { background: "#dfd" } : {}
                }
              >
                <Grid container spacing={1}>
                  {[
                    { name: "level", label: "ระดับการศึกษา" },
                    { name: "degree", label: "วุฒิการศึกษา" },
                    { name: "major", label: "สาขาวิชา" },
                    { name: "gpa", label: "ผลการเรียน" },
                    { name: "graduate_year", label: "ปีการศึกษาที่จบ" },
                    { name: "institute", label: "สถาบันการศึกษา" },
                    { name: "institute_province", label: "จังหวัดสถานศึกษา" },
                  ].map((field, key) => (
                    <Fragment key={key}>
                      <Grid
                        item
                        xs={6}
                        sm={5}
                        md={4}
                        lg={2}
                        className="bold"
                        style={{ textAlign: "right", fontSize: ".8rem" }}
                      >
                        {field.label} :
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={7}
                        md={8}
                        lg={4}
                        style={{ fontSize: ".8rem" }}
                      >
                        {history[field.name]}
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid
                    item
                    xs={6}
                    sm={5}
                    md={4}
                    lg={2}
                    className="bold"
                    style={{ textAlign: "right", fontSize: ".8rem" }}
                  >
                    วุฒิครู :
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sm={6}
                    md={7}
                    lg={4}
                    style={{ fontSize: ".8rem" }}
                  >
                    {history.teacher_qualified}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "right" }}
                    className="controlTd"
                  >
                    {history.history_id === historyId ? (
                      <>
                        <IconButton
                          color="error"
                          style={{ padding: "5px 5px" }}
                          onClick={() => deleteHistory(history.history_id)}
                        >
                          <Icon>clear</Icon>
                        </IconButton>
                        <IconButton
                          color="success"
                          style={{ padding: "5px 5px" }}
                          onClick={() => {
                            setForm({});
                            setHistoryId(null);
                          }}
                        >
                          <Icon>undo</Icon>
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          color="primary"
                          style={{ padding: "5px 5px" }}
                          onClick={() => {
                            setForm(history);
                            setHistoryId(history.history_id);
                          }}
                        >
                          <Icon>check_circle</Icon>
                        </IconButton>
                      </>
                    )}
                  </Grid>
                </Grid>
                <hr
                  style={{
                    border: "none",
                    borderBottom: "1px solid #ccc",
                    margin: "15px auto 20px",
                  }}
                />
              </div>
            ))}

          <div className={classes.head1}>การอบรมพัฒนา</div>
          <TableTrain edit={true} employee_id={employee._id} />
        </>
      )}
    </div>
  );
}

function LicenseDetail({
  license,
  selected_id,
  onSelected,
  onDisselect,
  onDelete,
}) {
  const labels = [
    { title: "ข้อมูลใบประกอบวิชาชีพ", field: "qualified_name" },
    { title: "เลขที่ใบประกอบวิชาชีพ", field: "license_no" },
    { title: "วันที่เริ่ม", field: "license_start" },
    { title: "วันที่สิ้นสุด", field: "license_expire" },
    { title: "ผ่อนผันครั้งที่", field: "license_renew" },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {labels.map((label, key) => (
          <Fragment key={key}>
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              lg={3}
              className="bold"
              style={{ textAlign: "right", fontSize: ".8rem", padding: "5px" }}
            >
              {label.title} :
            </Grid>
            <Grid
              item
              xs={5}
              sm={6}
              md={7}
              lg={8}
              style={{ fontSize: ".8rem", padding: "3px" }}
            >
              {license[label.field] || "-"}
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12} style={{ textAlign: "right" }} className="controlTd">
          {license._id === selected_id ? (
            <>
              <IconButton
                color="error"
                style={{ padding: "5px 5px" }}
                onClick={onDelete}
              >
                <Icon>clear</Icon>
              </IconButton>
              <IconButton
                color="success"
                style={{ padding: "5px 5px" }}
                onClick={onDisselect}
              >
                <Icon>undo</Icon>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                color="primary"
                style={{ padding: "5px 5px" }}
                onClick={onSelected}
              >
                <Icon>check_circle</Icon>
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function EducationHistory({ options, history, updateItem, addHistory }) {
  return (
    <Grid container spacing={2}>
      {/* <GridInput
        grid={[12, 6, 3, 3]}
        label="Level"
        placeholder="ระดับการศึกษา"
        onChange={(val) => updateItem({ level: val })}
        value={history.level}
      /> */}

      <GridSelect
        grid={[12, 6, 3, 3]}
        label="Level"
        options={options.hr_education_level}
        onChange={(val) => updateItem({ level: val })}
        value={history.level || "-"}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="Degree"
        placeholder="วุฒิการศึกษา"
        onChange={(val) => updateItem({ degree: val })}
        value={history.degree}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="Major"
        placeholder="สาขาวิชา"
        onChange={(val) => updateItem({ major: val })}
        value={history.major}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="GPA"
        placeholder="ผลการเรียน"
        onChange={(val) => updateItem({ gpa: val })}
        value={history.gpa}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="Graduate year"
        placeholder="ปีการศึกษาที่จบ"
        onChange={(val) => updateItem({ graduate_year: val })}
        value={history.graduate_year}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="Institute"
        placeholder="สถาบันการศึกษา"
        onChange={(val) => updateItem({ institute: val })}
        value={history.institute}
      />
      <GridInput
        grid={[12, 6, 3, 3]}
        label="Institute Province"
        placeholder="จังหวัดสถานศึกษา"
        onChange={(val) => updateItem({ institute_province: val })}
        value={history.institute_province}
      />
      <GridSelect
        grid={[12, 6, 3, 3]}
        label="Teacher qualified"
        options={[{ label: "มี" }, { label: "ไม่มี" }]}
        onChange={(val) => updateItem({ teacher_qualified: val })}
        value={history.teacher_qualified || "-"}
      />
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={addHistory}
          style={{ width: "150px" }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
