import Conf from "../Conf";
import axios from "axios";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export const fetchData = async (query) => {
  let result = await axios.post(Conf.endpoint.app + "/query", query, {
    headers: { Authorization: cookie.access_token },
  });
  if (result.data) {
    if (result.data.error) {
      alert(result.data.message);
      return null;
    } else return result.data;
  } else {
    alert("Error : bad request");
    return null;
  }
};

export const updateData = async (method, path, data) => {
  let result = await axios({
    method,
    url: Conf.endpoint.app + path,
    data,

    headers: { Authorization: cookie.access_token },
  });
  if (result.data) {
    if (result.data.error) {
      alert(result.data.error.message);
      return null;
    } else return result.data;
  } else {
    alert("Error : bad request");
    return null;
  }
};

export const getPermission = () => {
  return new Promise(async (resolve) => {
    let currentPath = window.location.pathname;
    let cookie = localStorage.getItem(Conf.cookie_name);
    cookie = JSON.parse(cookie);
    if (Conf.access_token) cookie.access_token = Conf.access_token;
    if (cookie && cookie.access_token) {
      let permission = await axios.get(
        Conf.endpoint.tonkla + "/hrm/admin/permission",
        { headers: { Authorization: cookie.access_token } }
      );
      if (permission.data.usn) {
        resolve(permission.data);
      } else if (currentPath !== process.env.PUBLIC_URL + "/") {
        window.location = process.env.PUBLIC_URL + "/";
      } else resolve(null);
    } else if (currentPath !== process.env.PUBLIC_URL + "/") {
      window.location = process.env.PUBLIC_URL + "/";
    } else {
      resolve(null);
    }
  });
};

export const getStampTime = (dt) => {
  return new Promise((resolve) => {
    var d = new Date();
    if (dt) d = new Date(dt);
    var dd = String(d.getDate()).padStart(2, "0");
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();
    var isoDate = yyyy + "-" + mm + "-" + dd;

    var h = String(d.getHours()).padStart(2, "0");
    var m = String(d.getMinutes()).padStart(2, "0");
    var s = String(d.getSeconds()).padStart(2, "0");
    resolve({
      iso_date: isoDate,
      time: h + ":" + m + ":" + s,
      detail: {
        day: dd,
        month: mm,
        year: yyyy,
        hour: h,
        minute: m,
        second: s,
      },
      stampTime: d.getTime(),
    });
  });
};

export const checkPermission = (profile, path) => {
  return new Promise(async (resolve) => {
    resolve(true);
  });
};

export const getFullname = (person) => {
  return new Promise(async (resolve) => {
    let fullname =
      (person.prefix || "") +
      " " +
      (person.first_name || "") +
      " " +
      (person.middle_name || "") +
      " " +
      (person.last_name || "");
    resolve(fullname);
  });
};

export const getOptionTypes = async (types) => {
  return new Promise(async (resolve) => {
    let match = {
      $or: [],
    };
    let r = [];
    for (let type of types) {
      match["$or"].push({ type });
      r[type] = [];
    }
    let options = await axios.post(
      Conf.endpoint.tonkla + "/hrm/query",
      {
        collection: "options",
        match: match,
        limit: 0,
        sort: { priority: 1, label: 1 },
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    if (options.data && options.data.result) {
      options = options.data.result;
      for (let type of types) {
        let arr = options.filter((j) => j.type === type);
        r[type] = arr;
      }
    }
    resolve(r);
  });
};

export const getDivisionStaff = async (division) => {
  return new Promise(async (resolve) => {
    let staff = await axios.post(
      Conf.endpoint.tonkla + "/hrm/query",
      {
        collection: "employees",
        match: { $and: [{ division: division }, { work_status: "ปกติ" }] },
        limit: 0,
        sort: { first_name: 1, last_name: 1 },
        select:
          "prefix first_name middle_name last_name nickname division current_position assessment_form_id assessment_form",
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    resolve(staff.data);
  });
};

export const getOptions = async (type, language) => {
  return new Promise(async (resolve) => {
    let match = {};
    if (language && language === "en") {
      match = {
        $and: [
          { type },
          { label_alt: { $ne: "" } },
          { label_alt: { $exists: true } },
        ],
      };
    } else {
      match = {
        $and: [{ type }, { label: { $ne: "" } }, { label: { $exists: true } }],
      };
    }
    let send = {
      collection: "options",
      match: match,
      limit: 0,
      sort: "priority",
    };
    let options = await axios.post(Conf.endpoint.tonkla + "/reg/query", send, {
      headers: {
        Authorization: cookie.access_token,
      },
    });
    resolve(options.data.result);
  });
};

// export const testGetAssessee = (assessor_id) => {
//   return new Promise(async (resolve) => {
//     let assessors = await fetchData({
//       collection: "assessors",
//       match: { assessor: assessor_id },
//       populate: {
//         path: "employee",
//         match: { assessment_form_id: { $exists: true } },
//         select:
//           "_id prefix first_name middle_name last_name assessment_form_id division",
//         populate: { path: "assessment_form_id" },
//       },
//     });
//     assessors = assessors.result;
//     let r = [];
//     for (let assessor of assessors) {
//       r.push(assessor.employee);
//     }
//     console.log(r);
//     resolve(r);
//   });
// };

// testGetAssessee("6246c1e38de64f22fb14a302");

export const getAssessee = (assessor_id) => {
  return new Promise(async (resolve) => {
    let assessors = await fetchData({
      collection: "assessors",
      match: { assessor: assessor_id },
      populate: {
        path: "employee",
        match: { assessment_form_id: { $exists: true } },
        select:
          "_id prefix first_name middle_name last_name assessment_form_id division",
        populate: { path: "assessment_form_id" },
      },
    });
    assessors = assessors.result;
    let r = [];
    for (let assessor of assessors) {
      r.push(assessor.employee);
    }
    resolve(r);
  });
};

export const getCurrentAssessment = () => {
  return new Promise(async (resolve) => {
    let r = await fetchData({
      collection: "assessment_schedules",
    });
    resolve(r.result[0] || null);
  });
};

export const sortJSON = (arr, field) => {
  return new Promise(async (resolve) => {
    arr = arr.sort((a, b) =>
      a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
    );
    resolve(arr);
  });
};

export const sortJSONDESC = (arr, field) => {
  return new Promise(async (resolve) => {
    arr = arr.sort((a, b) =>
      a[field] > b[field] ? 0 : b[field] > a[field] ? -1 : 1
    );
    resolve(arr);
  });
};

export const url2code = (url) => {
  return new Promise(async (resolve) => {
    url = url.replace("https://hcs.tonkla.ac.th/", "");
    url = url.replace("http://hcs.tonkla.ac.th/", "");
    resolve(url);
  });
};

export const groupBy = (array, key) => {
  return new Promise(async (resolve) => {
    let r = [];
    for (let arr of array) {
      let i = r.findIndex((j) => j[key] === arr[key]);
      if (i < 0) r.push(arr);
    }
    resolve(r);
  });
};
