import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { SelectBox } from "../components/InputComponents";
import {
  checkPermission,
  getDivisionStaff,
  getFullname,
  getOptionTypes,
  updateData,
  fetchData,
  getStampTime,
} from "../js/main";
import { months } from "../const";

export default function Schedule({ profile, page }) {
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (profile) {
        let permission = await checkPermission(profile);
        if (permission === true) {
          setState({ tabNo: 1 });
        }
      }
    })();
  }, [profile]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          {state && (
            <>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={state.tabNo}
                    onChange={(e, value) =>
                      setState({ ...state, tabNo: value })
                    }
                  >
                    <Tab label={<div>กำหนดการประเมิน</div>} />
                    <Tab label={<div>กำหนดแบบฟอร์ม</div>} />
                  </Tabs>
                </Box>
                <div style={{ marginTop: "15px" }}>
                  {{ 0: <ScheduleTab />, 1: <SelectFormTab /> }[state.tabNo]}
                </div>
              </Box>
            </>
          )}
        </div>
      )}
    </>
  );
}

function ScheduleTab() {
  const [form, setForm] = useState();
  const [years, setYears] = useState([]);
  const [schedule, setSchedule] = useState([]);
  function getSchedule() {
    return new Promise(async (resolve) => {
      let result = await fetchData({
        collection: "assessment_schedules",
        sort: { txt_stream: -1 },
      });
      for (let s of result.result) {
        let i = months.findIndex((j) => j.value === parseInt(s.month));
        s.month_name = months[i].label;
      }
      resolve(result.result);
    });
  }
  useEffect(() => {
    (async () => {
      let dt = await getStampTime();
      let years = [];
      for (let i = dt.detail.year - 5; i < dt.detail.year + 5; i++) {
        years.push({ label: i });
      }
      let schedule = await getSchedule();
      setSchedule(schedule);
      setYears(years);
      setForm({ month: parseInt(dt.detail.month), year: dt.detail.year });
    })();
  }, []);

  async function save(id) {
    let send = id ? { _id: id } : form;
    let result = await updateData("put", "/assessment/schedule", send);
    if (result) {
      let Schedule = await getSchedule();
      setSchedule(Schedule);
      if (!id) alert("Save completed");
    }
  }
  async function deleteSchedule(id) {
    if (window.confirm("Confirm to delete")) {
      let result = await updateData("delete", "/assessment/schedule/" + id);
      if (result) {
        let Schedule = [...schedule];
        Schedule = Schedule.filter((j) => j._id !== id);
        setSchedule(Schedule);
      }
    }
  }
  async function setCurrent(id) {
    if (window.confirm("Confirm to change current assessment")) {
      save(id);
    }
  }
  return (
    <div>
      {form && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "100px" }} align="center">
                  ลำดับ
                </TableCell>
                <TableCell align="center">เดือนที่ประเมิน</TableCell>
                <TableCell align="center">ปีที่ประเมิน</TableCell>
                <TableCell style={{ width: "120px" }} align="center">
                  ครั้งปัจจุบัน
                </TableCell>
                <TableCell style={{ width: "100px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Icon style={{ color: "#666" }}>add</Icon>
                </TableCell>
                <TableCell align="center" className="inputTd">
                  <SelectBox
                    options={months}
                    value={form.month || 1}
                    disabled_empty={true}
                    onChange={(val) => setForm({ ...form, month: val })}
                  />
                </TableCell>
                <TableCell align="center" className="inputTd">
                  <SelectBox
                    options={years}
                    value={form.year}
                    disabled_empty={true}
                    onChange={(val) => setForm({ ...form, year: val })}
                  />
                </TableCell>
                <TableCell />
                <TableCell align="center" className="controlTd">
                  <IconButton
                    component="span"
                    color="primary"
                    onClick={() => save()}
                  >
                    <Icon>save</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
              {schedule.map((item, key) => (
                <TableRow key={key} hover>
                  <TableCell align="center">{key + 1}</TableCell>
                  <TableCell align="center">{item.month_name}</TableCell>
                  <TableCell align="center">{item.year}</TableCell>
                  <TableCell align="center" className="controlTd">
                    {item.is_current === true ? (
                      <Icon color="success">done</Icon>
                    ) : (
                      <IconButton
                        component="span"
                        onClick={() => setCurrent(item._id)}
                      >
                        <Icon>radio_button_unchecked</Icon>
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center" className="controlTd">
                    <IconButton
                      component="span"
                      color="error"
                      onClick={() => deleteSchedule(item._id)}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function SelectFormTab() {
  const [divisions, setDivisions] = useState([]);
  const [division, setDivision] = useState();
  const [employees, setEmployees] = useState();
  const [forms, setForms] = useState([]);
  function getEmployees(ds) {
    return new Promise(async (resolve) => {
      let emp = await getDivisionStaff(ds);
      for (let e of emp.result) {
        e.fullname = await getFullname(e);
      }
      resolve(emp.result);
    });
  }
  useEffect(() => {
    (async () => {
      let divisions = await getOptionTypes(["hr_division"]);
      let division = divisions.hr_division[0].label;
      let forms = await fetchData({
        collection: "assessment_forms",
        sort: { abbrev: 1 },
        select: "name abbrev",
      });
      let Forms = [];
      for (let form of forms.result) {
        Forms.push({
          value: form._id,
          label: form.abbrev,
        });
      }
      setDivisions(divisions.hr_division);
      setForms(Forms);
      setDivision(division);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (division) {
        let employee = await getEmployees(division);
        setEmployees(employee);
      }
    })();
  }, [division]);
  async function changeForm(id, employee_id) {
    let Emp = [...employees];
    let i = Emp.findIndex((j) => j._id === employee_id);
    if (i > -1) {
      let name = forms.findIndex((j) => j.value === id);
      name = forms[name].label;
      let send = {
        _id: employee_id,
        assessment_form_id: id,
        assessment_form: name,
      };
      let result = await updateData("put", "/employee", send);
      if (result) {
        Emp[i].assessment_form = name;
        Emp[i].assessment_form_id = id;
        setEmployees(Emp);
      }
    }
  }
  return (
    <div>
      <SelectBox
        options={divisions}
        onChange={(val) => setDivision(val)}
        value={division}
        disabled_empty={true}
      />

      <TableContainer component={Paper} style={{ marginTop: "15px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "80px" }} align="center">
                ลำดับ
              </TableCell>
              <TableCell align="center">ชื่อ - นามสกุล</TableCell>
              <TableCell style={{ width: "200px" }} align="center">
                ตำแหน่ง
              </TableCell>
              <TableCell style={{ width: "300px" }} align="center">
                ฟอร์มประเมิน
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees && (
              <>
                {employees.map((emp, key) => (
                  <TableRow key={key}>
                    <TableCell align="center">{key + 1}</TableCell>
                    <TableCell>{emp.fullname}</TableCell>
                    <TableCell align="center">{emp.current_position}</TableCell>
                    <TableCell className="inputTd">
                      <SelectBox
                        options={forms}
                        value={emp.assessment_form_id}
                        onChange={(value) => changeForm(value, emp._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {employees.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="center"
                      style={{ lineHeight: "60px" }}
                    >
                      ไม่พบข้อมูล
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
