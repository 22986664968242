import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const { window } = props;
  const { menus } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List style={{ padding: "0px 0px" }}>
        {menus
          .filter((j) => !j.subOf)
          .map((menu, key) => (
            <React.Fragment key={key}>
              <Divider />
              <ListItem
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  borderBottom: "1px solid #ddd",
                }}
              >
                {menu.label}
              </ListItem>

              {menus
                .filter((j) => j.subOf === menu.menuId)
                .map((text, index) => (
                  <React.Fragment key={index}>
                    {text.newWindow === true ? (
                      <ListItem
                        button
                        style={{ padding: "5px 10px" }}
                        component="a"
                        href={process.env.PUBLIC_URL + text.path}
                        target="_blank"
                      >
                        <ListItemIcon style={{ minWidth: "40px" }}>
                          <Icon>{text.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          <div>{text.label}</div>
                        </ListItemText>
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        style={{ padding: "5px 10px" }}
                        onClick={() =>
                          navigate(process.env.PUBLIC_URL + text.path)
                        }
                        // component={Link}
                        // to={process.env.PUBLIC_URL + text.path}
                      >
                        <ListItemIcon style={{ minWidth: "40px" }}>
                          <Icon>{text.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          <div>{text.label}</div>
                        </ListItemText>
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        <ListItem
          button
          style={{ padding: "5px 10px" }}
          onClick={() => props.onSignOut()}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <Icon>lock_open</Icon>
          </ListItemIcon>
          <ListItemText>
            <div>ลงชื่อออก</div>
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            TONKLA HRM
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={() => props.onSignOut()}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Icon>lock_open</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
