const on_dev = false;
module.exports = {
  // access_token: "cab00922-4d58-4dc3-9bf0-45d65980d35e",
  fbKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
  fbAuthDomain: "tonkla-a1378.firebaseapp.com",
  endpoint: {
    tonkla:
      on_dev === true
        ? "http://localhost:3001"
        : // "https://tonkla-backend.azurewebsites.net"
          "https://tonkla-backend.azurewebsites.net",
    app:
      on_dev === true
        ? "http://localhost:3001/hrm"
        : "https://tonkla-backend.azurewebsites.net/hrm",

    upload: "https://hrm.tonkla.ac.th/files/upload.php",
    file: "https://hrm.tonkla.ac.th/files/file.php",
  },
  cookie_name: "tonkla-hrm-cookie",
};
