import React, { useState, useEffect, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { TextDatePicker, InputText } from "../components/InputComponents";
import { updateData, fetchData } from "../js/main";
import axios from "axios";
import Conf from "../Conf";

export default function TableTrain({ edit, employee_id }) {
  const filePackageSize = 300000;
  const [trains, setTrains] = useState();
  useEffect(() => {
    (async () => {
      if (employee_id) {
        let res = await fetchData({
          collection: "hr_trains",
          match: { employee_id },
          limit: 1000,
          sort: { date_start: -1 },
        });
        setTrains(res.result);
      }
    })();
  }, [employee_id]);

  async function uploadFile(form) {
    return new Promise(async (resolve) => {
      let len = form.file.length;
      let file_id = null;
      for (let i = 0; i < len; i += filePackageSize) {
        let file_stream = form.file.substring(i, i + filePackageSize);
        let send = {
          file_id,
          file_stream,
        };
        let result = await axios.post(Conf.endpoint.upload, send);
        if (result.data.file_id) file_id = result.data.file_id;
      }
      resolve(file_id);
      resolve(true);
    });
  }
  function save(form) {
    return new Promise(async (resolve) => {
      if (form.title) {
        let send = { ...form };
        if (send.file) {
          let fileUpload = await uploadFile(send);
          send.file_url = Conf.endpoint.file + "?id=" + fileUpload;
          delete send.file;
        }
        send.employee_id = employee_id;
        let result = await updateData("put", "/train", send);
        let Trains = [...trains];
        if (!form._id) Trains.push(result);
        else {
          let i = Trains.findIndex((j) => j._id === form._id);
          if (i > -1) {
            Trains[i].edit = false;
            Trains[i] = result;
          }
        }
        setTrains(Trains);
        alert("Save completed");
        resolve(true);
      } else {
        alert("Please fill out the information completely.");
        resolve(false);
      }
    });
  }
  function selectTrain(key, status) {
    let Trains = [...trains];
    Trains[key].edit = status;
    setTrains(Trains);
  }
  async function deleteTrain(id) {
    if (window.confirm("Confirm to delete")) {
      let result = await updateData("delete", "/train/" + id);
      if (result.success === true) {
        let Trains = [...trains];
        Trains = Trains.filter((j) => j._id !== id);
        setTrains(Trains);
      }
    }
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1100 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: "40px", fontSize: ".8rem" }}
              >
                ลำดับ
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "135px", fontSize: ".8rem" }}
              >
                วันที่เริ่ม
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "135px", fontSize: ".8rem" }}
              >
                วันที่สิ้นสุด
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "100px", fontSize: ".8rem" }}
              >
                เวลาเริ่ม
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "100px", fontSize: ".8rem" }}
              >
                เวลาสิ้นสุด
              </TableCell>
              <TableCell align="center" style={{ fontSize: ".8rem" }}>
                หัวข้อการอบรม
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "180px", fontSize: ".8rem" }}
              >
                สถานที่
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "60px", fontSize: ".8rem" }}
              >
                ไฟล์
              </TableCell>
              {edit === true && (
                <TableCell align="center" style={{ width: "80px" }}>
                  แก้ไข
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {edit === true && <EditRow onSave={save} />}
            {trains &&
              trains.map((train, key) => (
                <Fragment key={key}>
                  {train.edit !== true ? (
                    <ShowRow
                      train={{ ...train, no: key + 1 }}
                      onSelect={() => selectTrain(key, true)}
                    />
                  ) : (
                    <EditRow
                      onSave={save}
                      train={train}
                      onSelect={() => selectTrain(key, false)}
                      onDelete={deleteTrain}
                    />
                  )}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function ShowRow({ train, onSelect }) {
  async function download() {
    let file = await axios.get(train.file_url);
    let downloadLink = document.createElement("a");
    let filename = "download.pdf";
    downloadLink.href = file.data;
    downloadLink.download = filename;
    downloadLink.click();
  }
  return (
    <TableRow>
      <TableCell align="center">{train.no}</TableCell>
      <TableCell align="center">{train.date_start}</TableCell>
      <TableCell align="center">{train.date_end}</TableCell>
      <TableCell align="center">{train.time_start}</TableCell>
      <TableCell align="center">{train.time_end}</TableCell>
      <TableCell>{train.title}</TableCell>
      <TableCell align="center">{train.location}</TableCell>
      <TableCell align="center" className="controlTd">
        {train.file_url && (
          <IconButton
            color="primary"
            style={{ padding: "5px 5px" }}
            onClick={() => download()}
          >
            <Icon>download</Icon>
          </IconButton>
        )}
      </TableCell>
      <TableCell align="center" className="controlTd">
        <IconButton
          color="success"
          style={{ padding: "5px 5px" }}
          onClick={() => onSelect()}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function EditRow({ onSave, train, onSelect, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (train) {
      setForm({ ...train });
    } else setForm({});
  }, [train]);

  function changeFile(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setForm({ ...form, file: reader.result });
      };
    }
  }
  async function saveForm(form) {
    let result = await onSave(form);
    if (result === true && !form._id) setForm({});
  }
  return (
    <>
      {form && (
        <TableRow>
          <TableCell align="center" className="controlTd">
            {form._id ? (
              <IconButton color="error" onClick={() => onDelete(form._id)}>
                <Icon>clear</Icon>
              </IconButton>
            ) : (
              <Icon sx={{ color: "#888" }}>add</Icon>
            )}
          </TableCell>
          <TableCell className="inputTd">
            <TextDatePicker
              onChange={(val) => setForm({ ...form, date_start: val })}
              value={form.date_start || null}
              fullWidth
              inputStyle={{ fontSize: ".8rem" }}
              placeholder="วันที่เริ่ม"
            />
          </TableCell>
          <TableCell className="inputTd">
            <TextDatePicker
              onChange={(val) => setForm({ ...form, date_end: val })}
              value={form.date_end || null}
              inputStyle={{ fontSize: ".8rem" }}
              fullWidth
              placeholder="วันที่สิ้นสุด"
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, time_start: val })}
              value={form.time_start}
              inputStyle={{ fontSize: ".8rem" }}
              type="time"
              fullWidth
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, time_end: val })}
              value={form.time_end}
              inputStyle={{ fontSize: ".8rem" }}
              type="time"
              fullWidth
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, title: val })}
              value={form.title}
              inputStyle={{ fontSize: ".8rem" }}
              placeholder="หัวข้อ"
              fullWidth
            />
          </TableCell>
          <TableCell className="inputTd">
            <InputText
              onChange={(val) => setForm({ ...form, location: val })}
              value={form.location}
              inputStyle={{ fontSize: ".8rem" }}
              placeholder="สถานที่"
              fullWidth
            />
          </TableCell>
          <TableCell className="controlTd" align="center">
            <label>
              <input
                accept="application/pdf,application/PDF"
                type="file"
                onChange={changeFile}
                style={{ display: "none" }}
              />
              <IconButton color="success" component="span">
                <Icon>upload</Icon>
              </IconButton>
            </label>
          </TableCell>
          <TableCell className="controlTd" align="center">
            {form._id && (
              <IconButton color="success" onClick={() => onSelect()}>
                <Icon>undo</Icon>
              </IconButton>
            )}

            <IconButton color="primary" onClick={() => saveForm(form)}>
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
