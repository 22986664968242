import Login from "./Login";
import Person from "./data/Person";
// import Personal from "./data/Personal";
// import AbsentDay from "./data/AbsentDay";
// import WorkTime from "./data/WorkTime";
// import UploadPresent from "./data/UploadPresent";
import TeacherInfo from "./data/TeacherInfo";
// import Present from "./reports/Present";
import RptPerson from "./reports/Person";
import Assessor from "./assessments/Assessor";
import QFeeSchedule from "./assessments/Schedule";
import QFee from "./assessments/QFee";
import Options from "./admin/Options";

export const routePaths = [
  /*
        {
          path: "/blank",
          component: Blank,
        },*/
  {
    component: Login,
    path: "/",
  },
  {
    label: "ข้อมูล",
    menuId: 1,
    priority: 1,
    hrm_staff: true,
  },
  {
    label: "บุคลากร",
    menuId: 10,
    priority: 1,
    subOf: 1,
    icon: "group",
    component: Person,
    path: "/person",
    hrm_staff: true,
  },
  {
    label: "บุคลากร",
    icon: "group",
    component: Person,
    path: "/person/:id",
    hrm_staff: true,
  },
  // {
  //   label: "ข้อมูลส่วนบุคคล",
  //   menuId: 10,
  //   priority: 1,
  //   subOf: 1,
  //   icon: "person",
  //   component: Personal,
  //   path: "/personal",
  // },
  // {
  //   label: "เวลางาน",
  //   menuId: 12,
  //   priority: 1,
  //   subOf: 1,
  //   icon: "access_time",
  //   component: WorkTime,
  //   path: "/worktime",
  // },
  // {
  //   label: "วันหยุด - ลา",
  //   menuId: 13,
  //   priority: 1,
  //   subOf: 1,
  //   icon: "event_busy",
  //   component: AbsentDay,
  //   path: "/dayclose",
  // },
  // {
  //   label: "อัพโหลดข้อมูลการเข้างาน",
  //   menuId: 14,
  //   priority: 1,
  //   subOf: 1,
  //   icon: "upload",
  //   component: UploadPresent,
  //   path: "/uploadpresent",
  // },
  {
    label: "บุคลากรทางการศึกษา",
    menuId: 15,
    priority: 1,
    subOf: 1,
    icon: "school",
    component: TeacherInfo,
    path: "/teacherinfo",
    hrm_staff: true,
  },
  {
    label: "บุคลากรทางการศึกษา",
    icon: "school",
    component: TeacherInfo,
    path: "/teacherinfo/:id",
    hrm_staff: true,
  },
  {
    label: "การประเมิน",
    menuId: 2,
    priority: 2,
  },
  {
    label: "กำหนดผู้ประเมิน",
    menuId: 20,
    priority: 1,
    subOf: 2,
    icon: "history_edu",
    component: Assessor,
    path: "/assessment/assessor",
    hrm_staff: true,
  },
  {
    label: "กำหนดการประเมิน",
    menuId: 21,
    priority: 1,
    subOf: 2,
    icon: "event",
    component: QFeeSchedule,
    path: "/assessment/schedule",
    hrm_staff: true,
  },
  {
    label: "ประเมิน QFee",
    menuId: 22,
    priority: 1,
    subOf: 2,
    icon: "fact_check",
    component: QFee,
    path: "/assessment/qfee",
  },
  {
    label: "รายงาน",
    menuId: 3,
    priority: 2,
    hrm_staff: true,
  },
  {
    label: "บุคลากร",
    menuId: 30,
    priority: 1,
    subOf: 3,
    icon: "assignment_ind",
    component: RptPerson,
    path: "/report/person",
    hrm_staff: true,
  },
  // {
  //   label: "การเข้างาน",
  //   menuId: 31,
  //   priority: 1,
  //   subOf: 3,
  //   icon: "event_available",
  //   component: Present,
  //   path: "/report/present",
  //   hrm_staff: true,
  // },

  {
    label: "ผู้ดูแลระบบ",
    menuId: 4,
    priority: 1,
    hrm_staff: true,
  },
  {
    label: "กำหนดตัวเลือก",
    menuId: 41,
    priority: 1,
    subOf: 4,
    icon: "list",
    component: Options,
    path: "/admin/options",
    hrm_staff: true,
  },
];
