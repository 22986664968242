import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { SelectBox } from "../components/InputComponents";
import {
  checkPermission,
  getDivisionStaff,
  getFullname,
  getOptionTypes,
  updateData,
  fetchData,
} from "../js/main";

export default function AbsendDay({ profile, page }) {
  const [divisions, setDivisions] = useState([]);
  const [assessor, setAssessor] = useState({});
  const [assessment, setAssessment] = useState([]);

  useEffect(() => {
    (async () => {
      if (profile) {
        let permission = await checkPermission(profile);
        if (permission === true) {
          let divisions = await getOptionTypes(["hr_division"]);
          setDivisions(divisions.hr_division);
        }
      }
    })();
  }, [profile]);
  useEffect(() => {
    (async () => {
      let assessment = await fetchData({
        collection: "assessors",
        limit: 10000,
        populate: {
          path: "assessor",
          select: "prefix first_name middle_name last_name",
        },
      });
      setAssessment(assessment.result || []);
    })();
  }, [divisions]);
  function updateAssessment(oldAssessor, employee, newAssessment) {
    let Assessment = [...assessment];
    let i = Assessment.findIndex(
      (j) => j.assessor._id === oldAssessor && j.employee === employee
    );
    if (i > -1) {
      Assessment[i].assessor = newAssessment;
      setAssessment(Assessment);
    }
  }
  function save(person) {
    return new Promise(async (resolve) => {
      if (assessor._id) {
        if (person.assessor && person.assessor._id === assessor._id) {
          await updateData("delete", "/assessment/assessor/" + person._id);
          let Assessment = [...assessment];
          let i = Assessment.findIndex(
            (j) => j.assessor._id === assessor._id && j.employee === person._id
          );
          Assessment.splice(i, 1);
          setAssessment(Assessment);
          delete person.assessor;
        } else if (person.assessor) {
          if (window.confirm("ยืนยันการเปลี่ยนผูัประเมิน")) {
            let result = await updateData("put", "/assessment/assessor", {
              assessor: assessor._id,
              employee: person._id,
            });
            if (result) {
              updateAssessment(
                person.assessor._id,
                person._id,
                result.assessor
              );
              person.assessor = result.assessor;
            }
          }
        } else {
          let result = await updateData("put", "/assessment/assessor", {
            assessor: assessor._id,
            employee: person._id,
          });
          if (result) {
            person.assessor = result.assessor;
            setDivisions([...divisions]);
          }
        }
      }
      resolve(person);
    });
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <SelectAssessor
                divisions={divisions}
                assessor={assessor}
                onSelect={(person) => setAssessor(person)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectAssessee
                divisions={divisions}
                assessor={assessor}
                assessment={assessment}
                onSave={save}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

function SelectAssessor({ divisions, assessor, onSelect }) {
  const [division, setDivision] = useState();
  const [persons, setPersons] = useState([]);
  useEffect(() => {
    if (divisions[0]) {
      setDivision(divisions[0].label);
    }
  }, [divisions]);

  useEffect(() => {
    (async () => {
      if (division) {
        let result = await getDivisionStaff(division);
        let persons = [];
        for (let person of result.result) {
          person.fullname = await getFullname(person);
          persons.push(person);
        }
        setPersons(persons);
      }
    })();
  }, [division]);

  return (
    <Paper style={{ padding: "20px 15px" }}>
      {division && (
        <>
          <Grid container style={{ paddingBottom: "10px" }}>
            <Grid item>
              <Icon>person_add_alt</Icon>
            </Grid>
            <Grid item style={{ paddingLeft: "10px", fontWeight: 500 }}>
              เลือกผู้ประเมิน
            </Grid>
          </Grid>
          <SelectBox
            options={divisions}
            onChange={(val) => {
              setDivision(val);
              onSelect({});
            }}
            value={division}
            disabled_empty={true}
          />

          <List style={{ marginTop: "10px" }}>
            {persons.map((person, key) => (
              <ListItem disablePadding key={key}>
                <ListItemButton
                  onClick={() => onSelect(person)}
                  style={{ padding: "3px 5px" }}
                >
                  <ListItemIcon>
                    {assessor._id === person._id ? (
                      <Icon style={{ color: "#0a0" }}>check_circle</Icon>
                    ) : (
                      <Icon>radio_button_unchecked</Icon>
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <div>{person.fullname}</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            {!persons[0] && (
              <div
                style={{
                  lineHeight: "37px",
                  textAlign: "center",
                  color: "#888",
                }}
              >
                ไม่พบข้อมูล
              </div>
            )}
          </List>
        </>
      )}
    </Paper>
  );
}

function SelectAssessee({ divisions, assessor, assessment, onSave }) {
  const [division, setDivision] = useState();
  const [persons, setPersons] = useState([]);
  const [assessPerson, setAssessPerson] = useState();
  useEffect(() => {
    if (divisions[0] && !division) {
      setDivision(divisions[0].label);
    }
  }, [divisions, division]);

  useEffect(() => {
    if (assessment) {
      setAssessPerson(assessment);
    }
  }, [assessment]);

  useEffect(() => {
    (async () => {
      if (division) {
        let result = await getDivisionStaff(division);
        let persons = [];
        for (let person of result.result) {
          person.fullname = await getFullname(person);
          let i = assessPerson.findIndex((j) => j.employee === person._id);
          if (i > -1) person.assessor = assessPerson[i].assessor;
          persons.push(person);
        }
        setPersons(persons);
      }
    })();
  }, [division, assessPerson]);
  /*
  async function saveAssessor(person) {
    if (assessor._id) {
      let Persons = [...persons];
      let i = Persons.findIndex((j) => j._id === person._id);
      if (i > -1) {
        if (Persons[i].assessor === assessor._id) {
          let result = await updateData(
            "delete",
            "/assessment/assessor/" + person._id
          );
          delete Persons[i].assessor;
        } else if (Persons[i].assessor) {
          alert("ยืนยันการเปลี่ยนผูัประเมิน");
        } else {
          let result = await updateData("put", "/assessment/assessor", {
            assessor: assessor._id,
            employee: person._id,
          });
          if (result) Persons[i].assessor = assessor._id;
        }
        setPersons(Persons);
      }
    }
  }
  */
  async function saveAssessor(person) {
    let result = await onSave(person);
    let Persons = [...persons];
    let i = Persons.findIndex((j) => j._id === person._id);
    if (i > -1) {
      Persons[i] = result;
      setPersons(Persons);
    }
  }
  return (
    <Paper style={{ padding: "20px 15px" }}>
      {division && (
        <>
          <Grid container style={{ paddingBottom: "10px" }}>
            <Grid item>
              <Icon>person_add_alt</Icon>
            </Grid>
            <Grid item style={{ paddingLeft: "10px", fontWeight: 500 }}>
              เลือกผู้ถูกประเมิน
            </Grid>
          </Grid>
          <SelectBox
            options={divisions}
            onChange={(val) => setDivision(val)}
            value={division}
            disabled_empty={true}
          />

          <List style={{ marginTop: "10px" }}>
            {persons
              .filter((j) => j._id !== assessor._id)
              .map((person, key) => (
                <ListItem disablePadding key={key}>
                  <ListItemButton
                    onClick={() => saveAssessor(person)}
                    // onClick={() => onSave(person)}
                    style={{ padding: "3px 5px" }}
                  >
                    <ListItemIcon>
                      {person.assessor && person.assessor._id ? (
                        <Icon
                          style={
                            person.assessor._id === assessor._id
                              ? { color: "#0a0" }
                              : { color: "#aaa" }
                          }
                        >
                          check_circle
                        </Icon>
                      ) : (
                        <Icon>radio_button_unchecked</Icon>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <div>{person.fullname}</div>
                      {person.assessor && (
                        <div style={{ color: "#070", fontSize: ".7rem" }}>
                          ผู้ประเมิน :{person.assessor.first_name}{" "}
                          {person.assessor.last_name}
                        </div>
                      )}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            {!persons.filter((j) => j._id !== assessor._id)[0] && (
              <div
                style={{
                  lineHeight: "37px",
                  textAlign: "center",
                  color: "#888",
                }}
              >
                ไม่พบข้อมูล
              </div>
            )}
          </List>
        </>
      )}
    </Paper>
  );
}
