import React, { useEffect } from "react";
import axios from "axios";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Conf from "./Conf";
import { getPermission } from "./js/main";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
/*
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
*/
const theme = createTheme();

export default function SignInSide() {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: Conf.fbKey,
      authDomain: Conf.fbAuthDomain,
    });
  }
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (e) => console.log(e),
    },
  };
  useEffect(() => {
    const renderPage = async () => {
      let permission = await getPermission(true);
      if (permission && permission.usn) {
        window.location = process.env.PUBLIC_URL + "/person";
      } else {
        await firebase.auth().signOut();
        firebase.auth().onAuthStateChanged(async (user) => {
          console.log(user);
          if (user) {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then(async function (idToken) {
                let res = await axios.get(
                  Conf.endpoint.tonkla + "/hrm/admin/auth",
                  {
                    headers: { Authorization: idToken },
                  }
                );

                if (res.data.error) {
                  alert(res.data.error.message);
                  window.location = process.env.PUBLIC_URL + "/";
                } else {
                  let access_token = res.data.access_token;
                  localStorage.setItem(
                    Conf.cookie_name,
                    JSON.stringify({ access_token: access_token })
                  );
                  // window.location = process.env.PUBLIC_URL + "/person";
                  window.location = process.env.PUBLIC_URL + "/assessment/qfee";
                }
              });
          }
        });
      }
    };
    renderPage();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <div className="container authContainer">
              <div className="row">
                <div className="col s12" style={{ textAlign: "center" }}>
                  <h2
                    style={{
                      color: "#555",
                      fontSize: "2.8rem",
                      marginBottom: "13px",
                    }}
                  >
                    Authentication
                  </h2>
                  <hr
                    style={{
                      width: "70px",
                      background: "#4db6ac",
                      border: "1.2px solid #4db6ac",
                      margin: "auto",
                    }}
                  />
                </div>
                <div
                  className="col s12"
                  style={{ paddingTop: "30px", textAlign: "center" }}
                >
                  <div>
                    <StyledFirebaseAuth
                      uiConfig={uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "200",
                    color: "#bbb",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <i className="far fa-copyright"></i> All rights reserved
                  Tonkla school
                </div>
              </div>
            </div>
            {/* <br />
            <br />
            <div>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </div> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
