import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { checkPermission } from "../js/main";
import { GridSelect } from "../components/InputComponents";
import Summary from "./Person/Summary";
import Contract from "./Person/Contract";

export default function Person(props) {
  const [state, setState] = useState({ menuNo: 3 });
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile) {
        let permission = await checkPermission(props.profile);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props.profile]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Grid container spacing={1}>
            <GridSelect
              grid={[12, 12, 6, 5]}
              options={[
                { value: 1, label: "ประวัติบุคลากร" },
                { value: 2, label: "รายชื่อบุคลากร" },
                { value: 3, label: "สัญญาจ้าง" },
              ]}
              label="Select report"
              size="small"
              onChange={(val) => setState({ ...state, menuNo: val })}
              value={state.menuNo}
              disabled_empty={true}
            />
          </Grid>
          <br />
          {
            {
              2: <Summary profile={profile} />,
              3: <Contract profile={profile} />,
            }[state.menuNo]
          }
        </div>
      )}
    </>
  );
}
