import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Blank from "./Blank";
// import Test from "./Test";
import Header from "./components/Header";
/*
import Student from "./data/Student";
import QRCode from "./data/QRCode";
import Group from "./data/Group";
import GroupTable from "./data/GroupTable";
import User from "./setting/User";
import Options from "./setting/Options";
import CheckPresent from "./action/CheckPresent";
import Checkin from "./action/Checkin";
import RptCheckPresent from "./reports/CheckPresent";
import RptStudent from "./reports/Student";
import ExcelExport from "./components/ExcelExport";
import Test from "./components/Test";
*/
import Conf from "./Conf";
import { routePaths } from "./routePaths";
import { getPermission } from "./js/main";

function App(props) {
  const [pages, setPages] = useState([]);
  const [profile, setProfile] = useState();
  const [path, setPath] = useState(null);

  const noHeaderPaths = ["/", "/signout"];
  const noHeaderSubstring = ["/group/student/"];
  const getPages = async (profile) => {
    let Pages = routePaths;
    if (profile && profile.hrm_staff === true) setPages(Pages);
    else setPages(Pages.filter((j) => j.hrm_staff !== true));
    setPath(window.location.pathname);
  };
  useEffect(() => {
    (async () => {
      let p = await getPermission();
      if (p) {
        setProfile(p);
        console.log(Conf.access_token);
      }
    })();
  }, []);
  useEffect(() => {
    /*if (profile || window.location.pathname === "/")*/ getPages(profile);
  }, [profile]);
  function signOut() {
    localStorage.removeItem(Conf.cookie_name);
    window.location = process.env.PUBLIC_URL + "/";
  }
  function checkSubstringPath() {
    let r = false;
    for (let arr of noHeaderSubstring) {
      let subPath = path.substring(0, arr.length);
      if (arr === subPath) r = true;
    }
    return r;
  }
  return (
    <div className="App">
      <BrowserRouter>
        {profile &&
          path &&
          noHeaderPaths.indexOf(path) < 0 &&
          checkSubstringPath() === false && (
            <Header
              onSignOut={signOut}
              menus={pages.filter((j) => j.menuId)}
              {...props}
            />
          )}
        <Routes>
          {pages
            .filter((j) => j.component)
            .map((page, key) => (
              <Route
                key={key}
                path={`${process.env.PUBLIC_URL}${page.path}`}
                element={<page.component page={page} profile={profile} />}
              />
            ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
