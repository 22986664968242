import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
// import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

export default function ListEmployee(props) {
  const { employees, onSelect } = props;
  return (
    <Box sx={{ width: "100%" }} component={Paper}>
      <nav>
        <List>
          {employees.length === 0 && (
            <ListItem>
              <ListItemText>
                <div style={{ textAlign: "center", lineHeight: "60px" }}>
                  Not found result
                </div>
              </ListItemText>
            </ListItem>
          )}
          {employees.map((employee, key) => (
            <React.Fragment key={key}>
              <ListItem disablePadding>
                <ListItemButton
                  component={onSelect ? "button" : Link}
                  onClick={() => {
                    onSelect ? onSelect(employee) : console.log("bbb");
                  }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: "40px",
                      textAlign: "center",
                    }}
                  >
                    {key + props.skip + 1}
                  </ListItemIcon>
                  <ListItemText>
                    <div>
                      {employee.prefix} {employee.first_name}{" "}
                      {employee.middle_name} {employee.last_name}
                      {employee.nickname && (
                        <span style={{ marginLeft: "10px", color: "#080" }}>
                          ({employee.nickname})
                        </span>
                      )}
                    </div>
                    <div style={{ color: "#060", fontSize: ".7rem" }}>
                      {employee.division}
                    </div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              {key < employee.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </nav>
    </Box>
  );
}
