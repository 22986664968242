import React, { Fragment, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Radio from "@mui/material/Radio";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { SelectBox } from "../components/InputComponents";
import { months } from "../const";
import {
  fetchData,
  getAssessee,
  getFullname,
  getCurrentAssessment,
  updateData,
} from "../js/main";

export default function AbsendDay({ profile, page }) {
  //   const [form, setForm] = useState();
  const [form, setForm] = useState();
  const [employees, setEmployees] = useState();
  const [current, setCurrent] = useState();
  useEffect(() => {
    (async () => {
      if (profile) {
        let current = await getCurrentAssessment();
        if (current) {
          setCurrent(current);
          let match = {
            $and: [
              { email: profile.usn },
              // { assessment_form_id: { $exists: true } },
            ],
          };
          let emp = await fetchData({
            collection: "employees",
            match,
            select:
              "prefix first_name middle_name last_name email nickname current_position assessment_form_id division",
            populate: {
              path: "assessment_form_id",
            },
          });
          emp = emp.result || [];
          console.log(emp);
          if (emp[0]) {
            let assessee = await getAssessee(emp[0]._id);
            let assessor_id = emp[0]._id;
            console.log(assessee);
            if (emp[0].assessment_form_id) emp = [...emp, ...assessee];
            else emp = assessee;
            for (let e of emp) {
              e.assessment_form_id.employee = e._id;
              e.assessment_form_id.assessor = assessor_id;
              e.fullname = await getFullname(e);
              e.assessment_form_id.employee_fullname = e.fullname;
              e.assessment_form_id.employee_nickname = e.nickname;
              e.assessment_form_id.assessor_fullname = await getFullname(
                emp[0]
              );
              e.label = e.fullname;
              e.value = e._id;
            }
          }
          console.log(emp);
          setEmployees(emp);
          setForm(emp[0]);
        }
      }
    })();
  }, [profile]);

  function selectEmployee(employee_id) {
    let i = employees.findIndex((j) => j._id === employee_id);
    setForm(employees[i]);
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          {form && (
            <>
              <SelectBox
                label="เลือกผู้ถูกประเมิน"
                options={employees}
                onChange={(val) => {
                  selectEmployee(val);
                }}
                value={form._id}
                disabled_empty={true}
              />
              <AssessmentForm form={form} current={current} />
            </>
          )}
          <br />
        </div>
      )}
    </>
  );
}

function AssessmentForm(props) {
  const [state, setState] = useState({});
  const [form, setForm] = useState();
  const styles = {
    label: {
      fontSize: ".7rem",
      fontWeight: 600,
    },
  };
  useEffect(() => {
    function fetchScore() {
      return new Promise(async (resolve) => {
        let Form = { ...props.form.assessment_form_id };
        console.log(Form);
        delete Form._id;
        // let questions = [...props.form.assessment_form_id.questions];
        let scores = await fetchData({
          collection: "assessment_scores",
          match: {
            $and: [
              { assessor: props.form.assessment_form_id.assessor },
              { employee: props.form.assessment_form_id.employee },
              { schedule_id: props.current._id },
            ],
          },
        });
        if (scores.result && scores.result[0]) {
          Form._id = scores.result[0]._id;
          scores.result[0].questions = scores.result[0].questions.filter(
            (j) => j.score
          );
          for (let q of scores.result[0].questions) {
            let i = Form.questions.findIndex(
              (j) => j.question_id === q.question_id
            );
            if (i > -1) {
              Form.questions[i].score = q.score;
            }
          }
        }
        resolve(Form);
      });
    }
    (async () => {
      let totalScore = 0;
      let sumScore = 0;
      let maxScore = 4;
      let Form = await fetchScore();
      for (let q of Form.questions) {
        if (q.weight) {
          totalScore += parseFloat(q.weight);
          if (q.score) sumScore += (q.score / maxScore) * q.weight;
        }
      }
      setState({
        totalScore,
        percentScore: (sumScore / totalScore) * 100,
        sumScore,
      });
      setForm(Form);
    })();
  }, [props]);

  function calSumScore(questions) {
    return new Promise((resolve) => {
      let r = 0;
      let maxScore = 4;
      for (let q of questions) {
        if (q.score && q.weight && parseFloat(q.weight) > 0) {
          r += (q.score / maxScore) * q.weight;
        }
      }
      resolve(r);
    });
  }

  async function changeScore(question_id, score) {
    let questions = [...form.questions];
    let i = questions.findIndex((j) => j.question_id === question_id);
    questions[i].score = score;
    let sumScore = await calSumScore(questions);
    setState({
      ...state,
      percentScore: (sumScore / state.totalScore) * 100,
      sumScore,
    });
    setForm({ ...form, questions });
  }
  async function save() {
    let send = { ...form };
    let chk = send.questions.findIndex(
      (j) => (!j.score || j.score === 0) && j.weight && j.weight > 0
    );
    if (chk < 0) {
      send.schedule_id = props.current._id;
      send.schedule_month = props.current.month;
      send.schedule_year = props.current.year;
      send.score = state.percentScore;
      let result = await updateData("put", "/assessment/form/score", send);
      if (result) {
        setForm({ ...form, _id: result._id });
        alert("Save completed");
      }
    } else alert("Please fill completed information");
  }
  return (
    <div style={{ marginTop: "20px" }}>
      {form && (
        <>
          <h3 style={{ marginBottom: "5px" }} className="bold">
            {form.name}
          </h3>
          <div style={{ color: "#888" }}>
            รอบการประเมิน {months[props.current.month - 1].label}{" "}
            {props.current.year}
          </div>

          <Grid container style={{ marginTop: "15px" }} spacing={2}>
            <Grid item xs={12} md={6}>
              <div style={styles.label}>ชื่อ-นามสกุล</div>
              {form.employee_fullname}{" "}
              {form.employee_nickname && <>({form.employee_nickname})</>}
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={styles.label}>แผนก</div>
              {props.form.division}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="th" rowSpan={2}>
                        Description
                      </TableCell>
                      <TableCell
                        className="th"
                        rowSpan={2}
                        style={{ width: "80px" }}
                      >
                        Weight
                      </TableCell>
                      <TableCell className="th" colSpan={4}>
                        Score
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {[4, 3, 2, 1].map((val) => (
                        <TableCell
                          className="th"
                          style={{ width: "9%" }}
                          key={val}
                        >
                          {val}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {form.questions
                      .filter((j) => j.heading === true)
                      .map((question) => (
                        <Fragment key={question.question_id}>
                          <TableRow>
                            <TableCell className="bold" colSpan={5}>
                              {question.question}
                            </TableCell>
                          </TableRow>
                          {form.questions
                            .filter((j) => j.sub_of === question.question_id)
                            .map((q) => (
                              <TableRow hover key={q.no}>
                                <TableCell
                                  colSpan={!q.weight || q.weight <= 0 ? 6 : 1}
                                >
                                  <Grid container>
                                    <Grid item style={{ width: "30px" }}>
                                      {q.no}
                                    </Grid>
                                    <Grid item> {q.question}</Grid>
                                  </Grid>
                                </TableCell>
                                {q.weight && q.weight > 0 && (
                                  <Fragment>
                                    <TableCell align="center" className="bold">
                                      {q.weight}
                                    </TableCell>
                                    {[4, 3, 2, 1].map((r) => (
                                      <TableCell
                                        key={r}
                                        align="center"
                                        className="controlTd"
                                      >
                                        <Radio
                                          checked={parseInt(q.score) === r}
                                          style={{ padding: "3px 3px" }}
                                          onChange={(e) =>
                                            e.target.checked === true &&
                                            changeScore(q.question_id, r)
                                          }
                                        />
                                      </TableCell>
                                    ))}
                                  </Fragment>
                                )}
                              </TableRow>
                            ))}
                        </Fragment>
                      ))}
                    <TableRow>
                      <TableCell className="bold" align="right">
                        Total Score (100)
                      </TableCell>
                      <TableCell className="bold" align="center">
                        {state.percentScore.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                color="primary"
                variant="contained"
                startIcon={<Icon>save</Icon>}
                onClick={() => save()}
              >
                Save Form
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
